import { getFirestore, doc, setDoc, getDoc, deleteDoc, collection, getDocs } from "firebase/firestore";

const db = getFirestore();

class RapportX {
  constructor(restaurantId, date, value) {
    this.restaurantId = restaurantId;
    this.date = date;
    this.value = value;
  }

  // Save or update RapportX
  static async saveRapportX(restaurantId, date, value) {
    try {
      const rapportXRef = doc(db, "rapportX", `${restaurantId}_${date}`); // Document ID is a combination of restaurantId and date
      await setDoc(rapportXRef, { restaurantId, value }, { merge: true });
      console.log("RapportX saved successfully!");
    } catch (error) {
      console.error("Error saving RapportX:", error.message);
      throw new Error(error.message);
    }
  }

  // Get all RapportX by restaurantId
  static async getAllRapportX(restaurantId) {
    try {
      const rapportXSnapshot = await getDocs(collection(db, "rapportX"));
      const rapportXList = [];
      
      // Filter by restaurantId
      rapportXSnapshot.forEach((doc) => {
        if (doc.id.startsWith(restaurantId)) { // Check if document belongs to the restaurant
          rapportXList.push({ restaurantId, date: doc.id.split('_')[1], ...doc.data() });
        }
      });
      return rapportXList;
    } catch (error) {
      console.error("Error retrieving RapportX data:", error.message);
      throw new Error(error.message);
    }
  }

  // Get total RapportX per restaurant from each first of the month (01/MM/YYYY)
  static async getTotalRapportXPerMonth(restaurantId) {
    try {
      const rapportXSnapshot = await getDocs(collection(db, "rapportX"));
      let totalSum = 0; // Initialize the total sum variable
  
      // Get the current date information
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // getMonth() is zero-indexed, so add 1
      const currentYear = currentDate.getFullYear();
  
      // Loop through each document in the snapshot
      rapportXSnapshot.forEach((doc) => {
        if (doc.id.startsWith(restaurantId)) {
          const date = doc.id.split('_')[1]; // Extract date (DD/MM/YYYY)
          const [day, month, year] = date.split('/');
  
          // Check if the document is from the current month and year
          if (parseInt(month) === currentMonth && parseInt(year) === currentYear) {
            totalSum += doc.data().value; // Add the value of each document to the total sum
          }
        }
      });
  
      return totalSum; // Return the total sum of the current month's RapportX values
    } catch (error) {
      console.error("Error calculating total RapportX:", error.message);
      throw new Error(error.message);
    }
  }
  
    
  
  // Delete RapportX by restaurantId and date
  static async deleteRapportX(restaurantId, date) {
    try {
      const rapportXRef = doc(db, "rapportX", `${restaurantId}_${date}`);
      await deleteDoc(rapportXRef);
      console.log("RapportX deleted successfully!");
    } catch (error) {
      console.error("Error deleting RapportX:", error.message);
      throw new Error(error.message);
    }
  }
}

export default RapportX;

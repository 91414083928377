import React, { useState, useEffect } from "react";
import { 
  Button, TextField, Grid, Card, CardContent, Typography, 
  MenuItem, Box, Dialog, DialogActions, DialogContent, 
  DialogTitle, List, ListItem, ListItemText, Chip,
  Divider, IconButton, Tooltip, Paper, Stack
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Facture from '../Services/FactureFournisseur';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaidIcon from '@mui/icons-material/Paid';
import EventIcon from '@mui/icons-material/Event';

const StatusChip = styled(Chip)(({ theme, status }) => ({
  fontWeight: 600,
  borderRadius: 4,
  backgroundColor: 
    status === 'Payée' ? theme.palette.success.light :
    status === 'Non Payée' ? theme.palette.error.light :
    theme.palette.warning.light,
  color: 
    status === 'Payée' ? theme.palette.success.dark :
    status === 'Non Payée' ? theme.palette.error.dark :
    theme.palette.warning.dark,
}));

const PaymentIcon = ({ mode }) => {
  switch(mode) {
    case 'Chèque': return <ReceiptIcon fontSize="small" />;
    case 'Espèce': return <AttachMoneyIcon fontSize="small" />;
    case 'Virement Bancaire': return <PaidIcon fontSize="small" />;
    default: return null;
  }
};

const AddFactureDialog = ({ open, onClose, onAddFacture, fournisseurId }) => {
  const [formData, setFormData] = useState({
    factureNumero: '',
    achatHTVA: '',
    tvaDeductible: '',
    achatTTC: '',
    montantPaye: '',
    statut: 'Non Payée',
    resteAPayer: '',
    modeDePaiement: '',
    paymentnum: '',
    date: new Date().toISOString().split('T')[0],
  });

  useEffect(() => {
    const htva = parseFloat(formData.achatHTVA) || 0;
    const tva = parseFloat(formData.tvaDeductible) || 0;
    const ttc = htva + tva;
    const reste = ttc - (parseFloat(formData.montantPaye) || 0);
    
    setFormData(prev => ({
      ...prev,
      achatTTC: ttc.toFixed(2),
      resteAPayer: reste.toFixed(2),
      statut: reste <= 0 ? 'Payée' : (reste < ttc ? 'Panding' : 'Non Payée')
    }));
  }, [formData.achatHTVA, formData.tvaDeductible, formData.montantPaye]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleAddFacture = async () => {
    if (!formData.factureNumero || !formData.achatHTVA) {
      alert("Veuillez remplir les champs obligatoires (*)");
      return;
    }

    try {
      const restaurantId = localStorage.getItem("restaurantId");
      const facture = new Facture(
        fournisseurId,
        restaurantId,
        formData.factureNumero,
        formData.achatHTVA,
        formData.tvaDeductible,
        formData.achatTTC,
        formData.montantPaye,
        formData.statut,
        formData.resteAPayer,
        formData.modeDePaiement,
        formData.paymentnum,
        formData.date
      );

      await facture.saveFacture();
      onClose();
      onAddFacture();
      setFormData({
        factureNumero: '',
        achatHTVA: '',
        tvaDeductible: '',
        achatTTC: '',
        montantPaye: '',
        statut: 'Non Payée',
        resteAPayer: '',
        modeDePaiement: '',
        paymentnum: '',
        date: new Date().toISOString().split('T')[0],
      });
      alert("Facture ajoutée avec succès!");
      window.location.reload();
    } catch (error) {
      console.error("Erreur:", error);
      alert("Erreur lors de l'ajout de la facture");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
        <AddIcon sx={{ mr: 1 }} />
        Nouvelle Facture Fournisseur
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3} sx={{ pt: 2 }}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Numéro de Facture"
              name="factureNumero"
              value={formData.factureNumero}
              onChange={handleChange}
              InputProps={{
                startAdornment: <ReceiptIcon fontSize="small" sx={{ mr: 1, color: 'action.active' }} />,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="date"
              label="Date de Facturation"
              name="date"
              value={formData.date}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: <EventIcon fontSize="small" sx={{ mr: 1, color: 'action.active' }} />,
              }}
            />
          </Grid>
          
          <Grid item xs={12}>
            <Divider sx={{ mb: 2 }}>Détails Financiers</Divider>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <TextField
              required
              fullWidth
              type="number"
              label="HTVA (TND)"
              name="achatHTVA"
              value={formData.achatHTVA}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              type="number"
              label="TVA Déductible (TND)"
              name="tvaDeductible"
              value={formData.tvaDeductible}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="TTC (TND)"
              value={formData.achatTTC}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

         
          
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} variant="outlined" color="inherit">
          Annuler
        </Button>
        <Button onClick={handleAddFacture} variant="contained" color="primary">
          Enregistrer la Facture
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditFactureDialog = ({ open, onClose, facture, onEditFacture, fournisseurId }) => {
  const [factureNumero, setFactureNumero] = useState(facture.numero);
  const [achatHTVA, setAchatHTVA] = useState(facture.achatHTVA);
  const [tvaDeductible, setTvaDeductible] = useState(facture.tvaDeductible);
  const [achatTTC, setAchatTTC] = useState(facture.achatTTC);
  const [montantPaye, setMontantPaye] = useState(facture.montantPaye);
  const [statut, setStatut] = useState(facture.statut);
  const [resteAPayer, setResteAPayer] = useState(facture.resteAPayer);
  const [modeDePaiement, setModeDePaiement] = useState(facture.modeDePaiement);
  const [paymentnum, setPaymentnum] = useState(facture.paymentnum);
  const [date, setDate] = useState(facture.date);

  // Function to clear the data when "Annuler" is clicked
  const handleClearData = () => {
    setFactureNumero(facture.numero);
    setAchatHTVA(facture.achatHTVA);
    setTvaDeductible(facture.tvaDeductible);
    setAchatTTC(facture.achatTTC);
    setMontantPaye(facture.montantPaye);
    setStatut(facture.statut);
    setResteAPayer(facture.resteAPayer);
    setModeDePaiement(facture.modeDePaiement);
    setPaymentnum(facture.paymentnum);
    setDate(facture.date);
    
    // Close the dialog after clearing the data
    onClose();
  };

  const handleEditFacture = async () => {
    if (!factureNumero || !achatHTVA || !achatTTC) {
      alert("Please fill all required fields");
      return;
    }

    try {
      const restaurantId = localStorage.getItem("restaurantId");
      if (!restaurantId) {
        alert("Restaurant ID is missing. Please log in again.");
        return;
      }

      const updatedData = {
        fournisseurId,
        restaurantId,
        numero: factureNumero,
        achatHTVA,
        tvaDeductible,
        achatTTC,
        montantPaye,
        statut,
        resteAPayer,
        modeDePaiement,
        paymentnum,
        date,
      };

      console.log("Facture ID:", facture.id);
      console.log("Updated Data:", updatedData);

      await Facture.updateFacture(facture.id, fournisseurId, updatedData);

      alert("Facture updated successfully!");

      onClose();
      onEditFacture();
    } catch (error) {
      console.error("Error editing facture:", error.message || error, error.stack);

      alert("Error editing facture. Please check the logs for more details.");
    }
  };

  // Update resteAPayer whenever achatTTC or montantPaye changes
  const handleAchatTTCChange = (e) => {
    const newAchatTTC = e.target.value;
    setAchatTTC(newAchatTTC);
    setResteAPayer(newAchatTTC - montantPaye); // Calculate the remaining amount to pay
  };

  const handleMontantPayeChange = (e) => {
    const newMontantPaye = e.target.value;
    setMontantPaye(newMontantPaye);
    setResteAPayer(achatTTC - newMontantPaye); // Calculate the remaining amount to pay
  };



  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
        <EditIcon sx={{ mr: 1 }} />
        Modifier la Facture #{facture.numero}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Numero De Facture"
              variant="outlined"
              fullWidth
              value={factureNumero}
              onChange={(e) => setFactureNumero(e.target.value)}
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              label="Date"
              variant="outlined"
              fullWidth
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Achat HTVA"
              type="number"
              variant="outlined"
              fullWidth
              value={achatHTVA}
              onChange={(e) => setAchatHTVA(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="TVA Deductible"
              variant="outlined"
              type="number"
              fullWidth
              value={tvaDeductible}
              onChange={(e) => setTvaDeductible(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Achat TTC"
              variant="outlined"
              type="number"
              fullWidth
              value={achatTTC}
              onChange={handleAchatTTCChange} // Use the updated change handler
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Montant Payé"
              variant="outlined"
              type="number"
              fullWidth
              value={montantPaye}
              onChange={handleMontantPayeChange} // Use the updated change handler
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Reste A Payer"
              variant="outlined"
              type="number"
              fullWidth
              value={resteAPayer}
              onChange={(e) => setResteAPayer(e.target.value)}
              InputProps={{
                readOnly: true, // Reste à payer should be read-only as it is calculated automatically
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Statut"
              variant="outlined"
              fullWidth
              value={statut}
              onChange={(e) => setStatut(e.target.value)}
            >
              <MenuItem value="Payée" style={{ color: 'green' }}>Payée</MenuItem>
              <MenuItem value="Non Payée" style={{ color: 'red' }}>Non Payée</MenuItem>
              <MenuItem value="Panding" style={{ color: 'orange' }}>Panding</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Mode de Paiement"
              variant="outlined"
              fullWidth
              value={modeDePaiement}
              onChange={(e) => setModeDePaiement(e.target.value)}
            >
              <MenuItem value="Chèque">Chèque</MenuItem>
              <MenuItem value="Espèce">Espèce</MenuItem>
              <MenuItem value="Virement Bancaire">Virement Bancaire</MenuItem>
            </TextField>
          </Grid>

          {modeDePaiement === "Chèque" || modeDePaiement === "Virement Bancaire" ? (
            <Grid item xs={12} sm={6}>
              <TextField
                label="Numéro de Paiement"
                variant="outlined"
                fullWidth
                value={paymentnum}
                onChange={(e) => setPaymentnum(e.target.value)}
              />
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} variant="outlined" color="inherit">
          Annuler
        </Button>
        <Button onClick={handleEditFacture} variant="contained" color="primary">
          Mettre à Jour
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FactureComponent = ({ fournisseurId, fetchFactures }) => {
  const [factures, setFactures] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedFacture, setSelectedFacture] = useState(null);

  const fetchFacturesForFournisseur = async () => {
    try {
      const facturesList = await Facture.getFacturesByFournisseurId(fournisseurId);
      setFactures(facturesList);
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  const handleDeleteFacture = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette facture?")) {
      try {
        await Facture.deleteFacture(id);
        fetchFacturesForFournisseur();
      } catch (error) {
        console.error("Erreur:", error);
      }
    }
  };

  useEffect(() => {
    if (fournisseurId) fetchFacturesForFournisseur();
  }, [fournisseurId]);

  return (
    <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5" component="div">
          <AttachMoneyIcon sx={{ mr: 1, verticalAlign: 'bottom' }} />
          Gestion des Factures Fournisseurs
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenAddDialog(true)}
        >
          Nouvelle Facture
        </Button>
      </Stack>

      <AddFactureDialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        onAddFacture={fetchFacturesForFournisseur}
        fournisseurId={fournisseurId}
      />

      {selectedFacture && (
        <EditFactureDialog
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          facture={selectedFacture}
          onEditFacture={fetchFacturesForFournisseur}
          fournisseurId={fournisseurId}
        />
      )}

      <List dense>
        {factures.map((facture) => (
          <Card key={facture.id} sx={{ mb: 2, borderLeft: `4px solid ${facture.statut === 'Payée' ? '#4caf50' : '#f44336'}` }}>
            <CardContent>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={3}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <PaymentIcon mode={facture.modeDePaiement} />
                    <Typography variant="subtitle1">
                      #{facture.numero}
                    </Typography>
                  </Stack>
                  <Typography variant="body2" color="text.secondary">
                    {new Date(facture.date).toLocaleDateString()}
                  </Typography>
                </Grid>
                
                <Grid item xs={12} md={3}>
                  <Typography variant="body2">
                    TTC: {parseFloat(facture.achatTTC).toFixed(2)}TND
                  </Typography>
                  <Typography variant="body2">
                    Reste: {parseFloat(facture.resteAPayer).toFixed(2)}TND
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                  <StatusChip 
                    label={facture.statut} 
                    status={facture.statut} 
                    size="small" 
                  />
                </Grid>

                <Grid item xs={12} md={3} sx={{ textAlign: 'right' }}>
                  <Tooltip title="Modifier">
                    <IconButton 
                      onClick={() => {
                        setSelectedFacture(facture);
                        setOpenEditDialog(true);
                      }}
                      sx={{ mr: 1 }}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Supprimer">
                    <IconButton onClick={() => handleDeleteFacture(facture.id)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </List>

      {factures.length === 0 && (
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="body1" color="text.secondary">
            Aucune facture trouvée pour ce fournisseur
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default FactureComponent;
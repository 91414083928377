import React, { useState } from 'react';
import { 
  Avatar, 
  Typography, 
  Button, 
  Grid, 
  Card, 
  CardContent, 
  Chip,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  
  Box
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { 
  AdminPanelSettings, 
  Schedule, 
  Groups, 
  EditCalendar,
  Business,
  NotificationsActive,
  EventAvailable 
} from '@mui/icons-material';

const Profile = () => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const [employees, setEmployees] = useState([
    { id: 1, name: "John Chef", role: "Chef", shift: "14:00 - 22:00", days: ["Mon", "Wed", "Fri"] },
    { id: 2, name: "Sarah Manager", role: "Manager", shift: "09:00 - 17:00", days: ["Tue", "Thu", "Sat"] },
    { id: 3, name: "Mike Waiter", role: "Waiter", shift: "17:00 - 01:00", days: ["Mon", "Tue", "Wed"] },
  ]);

  const adminStats = [
    { title: "Total Employees", value: "47", icon: <Groups color="primary" /> },
    { title: "Active Shifts", value: "8", icon: <NotificationsActive color="secondary" /> },
    { title: "Pending Requests", value: "3", icon: <EventAvailable color="error" /> },
    { title: "Labor Cost", value: "$12,450", icon: <Business color="success" /> },
  ];

  const handleShiftUpdate = (newRow) => {
    setEmployees(employees.map(emp => emp.id === newRow.id ? newRow : emp));
    return newRow;
  };

  return (
    <Box sx={{ p: 4, minHeight: '100vh', backgroundColor: '#f8fafc' }}>
      {/* Admin Header */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4, gap: 3 }}>
        <Avatar sx={{ 
          width: 100, 
          height: 100, 
          bgcolor: '#1a237e',
          fontSize: '2rem'
        }}>
          <AdminPanelSettings fontSize="large" />
        </Avatar>
        
        <Box>
          <Typography variant="h4" sx={{ fontWeight: 700, color: '#1a237e' }}>
            {user.name || 'Admin Dashboard'}
            <Chip 
              label="Administrator" 
              color="primary" 
              sx={{ ml: 2, fontSize: '0.8rem' }}
              icon={<AdminPanelSettings />}
            />
          </Typography>
          
          <Box sx={{ mt: 1, display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              startIcon={<EditCalendar />}
              onClick={() => setScheduleOpen(true)}
              sx={{ bgcolor: '#3f51b5' }}
            >
              Manage Schedules
            </Button>
            <Button
              variant="outlined"
              startIcon={<Groups />}
              sx={{ color: '#1a237e', borderColor: '#1a237e' }}
            >
              View Staff
            </Button>
          </Box>
        </Box>
      </Box>

      <Divider sx={{ mb: 4 }} />

      {/* Admin Quick Stats */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {adminStats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ bgcolor: 'action.selected', p: 1.5, borderRadius: '50%' }}>
                  {stat.icon}
                </Box>
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    {stat.title}
                  </Typography>
                  <Typography variant="h6" fontWeight={600}>
                    {stat.value}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Schedule Management Dialog */}
      <Dialog 
        fullScreen 
        open={scheduleOpen} 
        onClose={() => setScheduleOpen(false)}
      >
        <DialogTitle sx={{ bgcolor: '#f5f5f5' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Shift Management</Typography>
            <Button 
              onClick={() => setScheduleOpen(false)}
              color="primary"
            >
              Close
            </Button>
          </Box>
        </DialogTitle>
        
        <DialogContent>
          <DataGrid
            rows={employees}
            columns={[
              { 
                field: 'name', 
                headerName: 'Employee', 
                width: 200,
                renderCell: (params) => (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Avatar sx={{ 
                      bgcolor: params.row.role === 'Chef' ? '#ff5722' : '#3f51b5',
                      width: 32, 
                      height: 32 
                    }}>
                      {params.row.name[0]}
                    </Avatar>
                    {params.value}
                  </Box>
                )
              },
              { 
                field: 'role', 
                headerName: 'Role', 
                width: 150,
                editable: true 
              },
              { 
                field: 'shift', 
                headerName: 'Shift Hours', 
                width: 150,
                editable: true 
              },
              { 
                field: 'days', 
                headerName: 'Working Days', 
                width: 250,
                editable: true,
                renderCell: (params) => (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {params.value.map(day => (
                      <Chip 
                        key={day} 
                        label={day} 
                        size="small" 
                        color="primary"
                      />
                    ))}
                  </Box>
                )
              },
            ]}
            processRowUpdate={handleShiftUpdate}
            sx={{ height: 600, mt: 2 }}
            disableSelectionOnClick
          />

          {/* Calendar View */}
          <Box sx={{ mt: 4, p: 2, bgcolor: 'background.paper', borderRadius: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Weekly Schedule Overview</Typography>
            {/* Implement calendar component here */}
            <Box sx={{ 
              height: 400, 
              bgcolor: '#f8f8f8', 
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#666'
            }}>
              Calendar Integration Area
            </Box>
          </Box>
        </DialogContent>

        <DialogActions sx={{ bgcolor: '#f5f5f5', p: 2 }}>
          <Button 
            variant="contained" 
            color="primary"
            onClick={() => {
              // Add save logic here
              setScheduleOpen(false);
            }}
          >
            Save Changes
          </Button>
          <Button 
            onClick={() => setScheduleOpen(false)}
            color="inherit"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Recent System Activities */}
      <Card sx={{ mt: 4, borderRadius: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
            Recent System Activities
          </Typography>
          <List>
            {[
              "Updated kitchen staff shifts",
              "Approved 3 time-off requests",
              "Modified holiday schedule",
              "Processed payroll for 45 employees"
            ].map((activity, index) => (
              <ListItem key={index} sx={{ py: 1 }}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <Schedule color="primary" />
                </ListItemIcon>
                <ListItemText primary={activity} />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Profile;
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  Divider,
  IconButton,
  Chip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Skeleton,
  Stack
} from '@mui/material';
import {
  Delete,
  Edit,
  AttachMoney,
  CalendarToday,
  CheckCircle,
  PendingActions,
  ErrorOutline,
  DateRange
} from '@mui/icons-material';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';

const db = getFirestore();

const TimelineItem = styled(ListItem)(({ theme }) => ({
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateX(5px)',
    backgroundColor: theme.palette.action.hover
  }
}));

const DateBadge = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  width: 'fit-content'
}));

const getEntriesByPartenaireId = async (partenaireId) => {
  try {
    const querySnapshot = await getDocs(collection(db, 'MontantDateStatue'));
    return querySnapshot.docs
      .filter(doc => doc.data().PartenaireId === partenaireId)
      .map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    throw new Error('Error retrieving entries: ' + error.message);
  }
};

const EditDialog = ({ open, entry, onClose, onSave }) => {
  const [editData, setEditData] = useState({ ...entry });

  const handleChange = (field) => (e) => {
    setEditData(prev => ({ ...prev, [field]: e.target.value }));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
        <Edit sx={{ mr: 1 }} />
        Edit Transaction
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={3} sx={{ pt: 2 }}>
          <DateBadge>
            <CalendarToday fontSize="small" />
            <Typography variant="body2">
              {format(new Date(editData.DateDebut), 'MMM dd')} - 
              {format(new Date(editData.DateFin), 'MMM dd, yyyy')}
            </Typography>
          </DateBadge>

          <TextField
            label="Amount Received"
            type="number"
            value={editData.MontantRecu}
            onChange={handleChange('MontantRecu')}
            fullWidth
            InputProps={{
              startAdornment: <AttachMoney fontSize="small" sx={{ mr: 1 }} />
            }}
          />

          <TextField
            select
            label="Status"
            value={editData.Statue}
            onChange={handleChange('Statue')}
            fullWidth
          >
            <MenuItem value="Validé"><CheckCircle sx={{ mr: 1, color: 'success.main' }} /> Approved</MenuItem>
            <MenuItem value="En attente"><PendingActions sx={{ mr: 1, color: 'warning.main' }} /> Pending</MenuItem>
            <MenuItem value="A Verifier"><ErrorOutline sx={{ mr: 1, color: 'error.main' }} /> A Verifier</MenuItem>
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">Cancel</Button>
        <Button onClick={() => onSave(editData)} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

const MontantDateStatueList = ({ partenaireId }) => {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editEntry, setEditEntry] = useState(null);
  const [dateFilter, setDateFilter] = useState('all');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (partenaireId) {
          const data = await getEntriesByPartenaireId(partenaireId);
          setEntries(data.sort((a, b) => new Date(b.DateDebut) - new Date(a.DateDebut)));
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [partenaireId]);

  const filteredEntries = entries.filter(entry => {
    const now = new Date();
    const entryDate = new Date(entry.DateDebut);
    switch (dateFilter) {
      case 'week': return entryDate > new Date(now.setDate(now.getDate() - 7));
      case 'month': return entryDate > new Date(now.setMonth(now.getMonth() - 1));
      default: return true;
    }
  });

  const handleSave = async (updatedData) => {
    try {
      await updateDoc(doc(db, 'MontantDateStatue', updatedData.id), updatedData);
      setEntries(entries.map(entry => entry.id === updatedData.id ? updatedData : entry));
      setEditEntry(null);
    } catch (err) {
      alert('Update error: ' + err.message);
    }
  };

  const StatusIcon = ({ status }) => {
    switch (status) {
      case 'Validé': return <CheckCircle color="success" />;
      case 'En attente': return <PendingActions color="warning" />;
      default: return <ErrorOutline color="error" />;
    }
  };

  if (loading) return (
    <Stack spacing={2}>
      {[1, 2, 3].map((i) => (
        <Skeleton key={i} variant="rectangular" height={80} />
      ))}
    </Stack>
  );

  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <DateRange fontSize="large" color="primary" />
          Transaction Timeline
        </Typography>
        
        <TextField
          select
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
          size="small"
          sx={{ width: 120 }}
        >
          <MenuItem value="all">All Dates</MenuItem>
          <MenuItem value="week">Last Week</MenuItem>
          <MenuItem value="month">Last Month</MenuItem>
        </TextField>
      </Box>

      <List>
        {filteredEntries.map((entry) => (
          <React.Fragment key={entry.id}>
            <TimelineItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'background.default' }}>
                  <StatusIcon status={entry.Statue} />
                </Avatar>
              </ListItemAvatar>
              
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="subtitle1">
                      {parseFloat(entry.MontantRecu).toLocaleString('fr-FR')} TND
                    </Typography>
                    <Chip 
                      label={entry.Statue} 
                      size="small"
                      sx={{
                        backgroundColor: 
                          entry.Statue === 'Validé' ? 'success.light' :
                          entry.Statue === 'En attente' ? 'warning.light' : 'error.light',
                        color: 
                          entry.Statue === 'Validé' ? 'success.dark' :
                          entry.Statue === 'En attente' ? 'warning.dark' : 'error.dark'
                      }}
                    />
                  </Box>
                }
                secondary={
                  <Box sx={{ mt: 1, display: 'flex', gap: 2 }}>
                    <DateBadge>
                      <CalendarToday fontSize="small" />
                      <Typography variant="body2">
                        {format(new Date(entry.DateDebut), 'MMM dd')} - 
                        {format(new Date(entry.DateFin), 'MMM dd, yyyy')}
                      </Typography>
                    </DateBadge>
                    <Typography variant="body2" color="text.secondary">
                      ECART: {entry.Ecart}
                    </Typography>
                  </Box>
                }
              />

              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton onClick={() => setEditEntry(entry)} size="small">
                  <Edit fontSize="small" />
                </IconButton>
                <IconButton onClick={() => {
                  if (window.confirm('Delete this transaction?')) {
                    deleteDoc(doc(db, 'MontantDateStatue', entry.id));
                    setEntries(entries.filter(e => e.id !== entry.id));
                  }
                }} size="small" color="error">
                  <Delete fontSize="small" />
                </IconButton>
              </Box>
            </TimelineItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
      </List>

      {filteredEntries.length === 0 && (
        <Box sx={{ textAlign: 'center', p: 4 }}>
          <Typography variant="body1" color="text.secondary">
            No transactions found for selected period
          </Typography>
        </Box>
      )}

      {editEntry && (
        <EditDialog
          open={!!editEntry}
          entry={editEntry}
          onClose={() => setEditEntry(null)}
          onSave={handleSave}
        />
      )}
    </Box>
  );
};

export default MontantDateStatueList;
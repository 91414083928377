import React, { useState, useEffect } from "react";
import RH from "../Services/RH";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Avatar,
  Snackbar,
  CircularProgress,
  IconButton,
  useTheme,
  Grow,
  Divider,
  Skeleton,
} from "@mui/material";
import ExcelSearchScreen from "./RHEXCEL";
import { useNavigate } from "react-router-dom";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Info as InfoIcon,
  Work as WorkIcon,
  Person as PersonIcon,
  Groups as TeamsIcon,
  Paid as SalaryIcon,
  Fingerprint as CINIcon,
  ContactPhone as PhoneIcon,
} from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
import { motion } from "framer-motion";

const roleGroups = {
  Management: ["Gérant", "Responsable de salle"],
  Cuisine: ["Chef de cuisine", "Cuisinier", "Aide-cuisinier"],
  Service: ["Serveur", "Barman", "Barmaid", "Hôte", "Hôtesse d'accueil"],
  Support: ["Plongeur"]
};

const statusColors = {
  Celibataire: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
  Marie: "linear-gradient(45deg, #4CAF50 30%, #81C784 90%)",
  "M+1": "linear-gradient(45deg, #FF9800 30%, #FFB74D 90%)",
  "M+2": "linear-gradient(45deg, #00BCD4 30%, #80DEEA 90%)",
  "M+3": "linear-gradient(45deg, #9C27B0 30%, #CE93D8 90%)",
  "M+4": "linear-gradient(45deg, #F44336 30%, #EF9A9A 90%)"
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RHPage = () => {
  const theme = useTheme();
  const restaurantId = localStorage.getItem("restaurantId");
  const [workers, setWorkers] = useState([]);
  const navigate = useNavigate();
  const [newWorker, setNewWorker] = useState({
    name: "",
    prenom: "",
    num: "",
    cin: "",
    numcnss: "",
    role: "",
    statue: "",
    salary: "",
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    const fetchWorkers = async () => {
      try {
        const fetchedWorkers = await RH.getAllWorkersByRestaurantId(restaurantId);
        setWorkers(fetchedWorkers);
      } catch (error) {
        showSnackbar("Error fetching workers: " + error.message, "error");
      } finally {
        setLoading(false);
      }
    };
    fetchWorkers();
  }, [restaurantId]);

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleAddWorker = async () => {
    const requiredFields = ["name", "prenom", "num", "cin", "statue", "salary"];
    const missingFields = requiredFields.filter(field => !newWorker[field]);
    
    if (missingFields.length > 0) {
      showSnackbar(`Missing required fields: ${missingFields.join(", ")}`, "error");
      return;
    }

    try {
      const addedWorker = await RH.addWorker({ ...newWorker, restaurantId });
      setWorkers([...workers, addedWorker]);
      resetForm();
      showSnackbar("Worker added successfully!");
    } catch (error) {
      showSnackbar("Error adding worker: " + error.message, "error");
    }
  };

  const handleDeleteWorker = async (workerId) => {
    try {
      await RH.deleteWorker(workerId, restaurantId);
      setWorkers(workers.filter((worker) => worker.id !== workerId));
      showSnackbar("Worker deleted successfully!");
    } catch (error) {
      showSnackbar("Error deleting worker: " + error.message, "error");
    }
  };

  const resetForm = () => {
    setNewWorker({
      name: "",
      prenom: "",
      num: "",
      cin: "",
      numcnss: "",
      role: "",
      statue: "",
      salary: "",
    });
    setIsDialogOpen(false);
  };

  return (
    <Container 
  maxWidth="lg" 
  sx={{ 
    py: 3,
    overflowX: 'hidden', // Prevents horizontal scrolling
    width: '100%',       // Ensure it takes full width
    maxWidth: '100vw'    // Prevents exceeding viewport width
  }}
>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box sx={{
          textAlign: "center",
          mb: 4,
          background: "linear-gradient(135deg, #3f51b5 0%, #2196f3 100%)",
          borderRadius: 4,
          py: 6,
          px: 2,
          boxShadow: 3,
          color: "white",
          
          width: '95%'
        }}>
          <TeamsIcon sx={{ fontSize: 60, mb: 2, filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.2))" }} />
          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontWeight: 800,
              letterSpacing: 1.5,
              textShadow: "0 2px 4px rgba(0,0,0,0.2)",
              mb: 2
            }}
          >
            Team Management
          </Typography>
          <Typography variant="h6" sx={{ opacity: 0.9 }}>
            Manage your restaurant staff with elegance and efficiency
          </Typography>
        </Box>

        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <ExcelSearchScreen />
        </Box>
      </motion.div>

      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 4,
        background: theme.palette.background.paper,
        borderRadius: 3,
        p: 3,
        boxShadow: 1,
        width: '94%'
      }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <WorkIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
          <Box>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              {workers.length} Team Members
            </Typography>

          </Box>
        </Box>
        <motion.div whileHover={{ scale: 1.05 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setIsDialogOpen(true)}
            sx={{
              borderRadius: 50,
              px: 4,
              py: 1.5,
              fontWeight: 700,
              background: "linear-gradient(135deg, #3f51b5 0%, #2196f3 100%)",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)"
            }}
          >
            New Member
          </Button>
        </motion.div>
      </Box>

      {loading ? (
        <Grid container spacing={3}>
          {[1, 2, 3].map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item}>
              <Skeleton variant="rectangular" height={200} sx={{ borderRadius: 3 }} />
            </Grid>
          ))}
        </Grid>
      ) : workers.length === 0 ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{
            textAlign: "center",
            p: 8,
            borderRadius: 3,
            background: theme.palette.background.paper,
            boxShadow: 1
          }}>
            <Typography variant="h5" color="textSecondary" gutterBottom>
              No Team Members Found
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
              Start building your dream team by adding the first member
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => setIsDialogOpen(true)}
              sx={{ borderRadius: 50, px: 4 }}
            >
              Add First Member
            </Button>
          </Box>
        </motion.div>
      ) : (
        <Grid container spacing={3} sx={{ width: '100%', margin: 0 }}>
          {workers.map((worker, index) => (
            <Grid item xs={12} sm={6} md={4} key={worker.id}>
              <Grow in={true} timeout={index * 150}>
                <Card sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 3,
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "translateY(-8px)",
                    boxShadow: theme.shadows[8]
                  },
                  position: "relative",
                  overflow: "visible",
                  "&:before": {
                    content: '""',
                    position: "absolute",
                    top: -2,
                    left: -2,
                    right: -2,
                    bottom: -2,
                    background: statusColors[worker.statue],
                    borderRadius: 5,
                    zIndex: -1,
                    opacity: 0.1
                  }
                }}>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      position: "relative"
                    }}>
                      <Avatar sx={{
                        width: 60,
                        height: 60,
                        mr: 2,
                        border: "2px solid " + theme.palette.primary.main
                      }}>
                        <PersonIcon sx={{ fontSize: 32 }} />
                      </Avatar>
                      <Box>
                        <Typography variant="h6" fontWeight={700}>
                          {worker.name} {worker.prenom}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {worker.role}
                        </Typography>
                      </Box>
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <Grid container spacing={1} sx={{ mb: 2 }}>
                      <Grid item xs={6}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          <CINIcon color="action" fontSize="small" />
                          <Typography variant="body2">CIN: {worker.cin}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          <PhoneIcon color="action" fontSize="small" />
                          <Typography variant="body2">{worker.num}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          <SalaryIcon color="action" fontSize="small" />
                          <Typography variant="body2" fontWeight={500}>
                            Salary: {worker.salary} TND
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Box sx={{
                      position: "absolute",
                      top: 16,
                      right: 16,
                      background: statusColors[worker.statue],
                      borderRadius: 2,
                      px: 1.5,
                      py: 0.5,
                      color: "white",
                      fontWeight: 600,
                      fontSize: 12
                    }}>
                      {worker.statue}
                    </Box>

                    <Box sx={{
                      display: "flex",
                      gap: 1,
                      mt: 2,
                      "& button": {
                        transition: "transform 0.2s",
                        "&:hover": {
                          transform: "scale(1.1)"
                        }
                      }
                    }}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<InfoIcon />}
                        onClick={() => navigate(`/worker/${worker.id}`)}
                        fullWidth
                        sx={{ borderRadius: 2 }}
                      >
                        Details
                      </Button>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteWorker(worker.id)}
                        sx={{
                          background: "rgba(244, 67, 54, 0.1)",
                          "&:hover": { background: "rgba(244, 67, 54, 0.2)" }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              </Grow>
            </Grid>
          ))}
        </Grid>
      )}
 <Dialog open={isDialogOpen} onClose={resetForm} fullWidth maxWidth="sm">
        <DialogTitle sx={{ bgcolor: theme.palette.primary.main, color: "white" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <PersonIcon />
            New Employee Registration
          </Box>
        </DialogTitle>
        <DialogContent sx={{ py: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                required
                value={newWorker.name}
                onChange={(e) => setNewWorker({ ...newWorker, name: e.target.value })}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                required
                value={newWorker.prenom}
                onChange={(e) => setNewWorker({ ...newWorker, prenom: e.target.value })}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                variant="outlined"
                type="tel"
                fullWidth
                required
                value={newWorker.num}
                onChange={(e) => setNewWorker({ ...newWorker, num: e.target.value })}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="CIN"
                variant="outlined"
                fullWidth
                required
                inputProps={{ maxLength: 8 }}
                value={newWorker.cin}
                onChange={(e) => setNewWorker({ ...newWorker, cin: e.target.value.slice(0, 8) })}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="CNSS Number"
                variant="outlined"
                fullWidth
                value={newWorker.numcnss}
                onChange={(e) => setNewWorker({ ...newWorker, numcnss: e.target.value })}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required sx={{ mb: 2 }}>
                <InputLabel>Position</InputLabel>
                <Select
                  value={newWorker.role}
                  onChange={(e) => setNewWorker({ ...newWorker, role: e.target.value })}
                  label="Position"
                >
                  {Object.entries(roleGroups).map(([group, roles]) => [
                    <MenuItem disabled key={group}>
                      <em>{group}</em>
                    </MenuItem>,
                    ...roles.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))
                  ])}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required sx={{ mb: 2 }}>
                <InputLabel>Family Status</InputLabel>
                <Select
                  value={newWorker.statue}
                  onChange={(e) => setNewWorker({ ...newWorker, statue: e.target.value })}
                  label="Family Status"
                >
                  {Object.keys(statusColors).map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Monthly Salary (TND)"
                variant="outlined"
                type="number"
                fullWidth
                required
                InputProps={{ endAdornment: "TND" }}
                value={newWorker.salary}
                onChange={(e) => setNewWorker({ ...newWorker, salary: e.target.value })}
                sx={{ mb: 2 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={resetForm} color="inherit">
            Cancel
          </Button>
          <Button
            onClick={handleAddWorker}
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add Employee
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RHPage;
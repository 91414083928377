import React, { useState, useEffect } from "react";
import Product from "../Services/FoodCost";
import Ingredient from "../Services/Ingredient";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Checkbox,
  ListItemText,
  Chip,
  Divider,
  Avatar,
  Rating
} from "@mui/material";
import { AddAPhoto, Save, Cancel, Fastfood, Edit, Delete } from "@mui/icons-material";
import ExcelTableFoodCost from "../Components/TableExcel/ExcelFoodCost";
import { useNavigate } from "react-router-dom";

const calculateProductCost = (ingredients, productIngredients) => {
  return productIngredients.reduce((totalCost, { ingredientId, quantity }) => {
    const ingredient = ingredients.find(ing => ing.id === ingredientId);
    return totalCost + (ingredient?.price || 0) * (quantity || 0);
  }, 0);
};

const FoodCostPage = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [menuDialogOpen, setMenuDialogOpen] = useState(false);
  const [ratings, setRatings] = useState({});
  const restaurantId = localStorage.getItem("restaurantId");
  const [newProduct, setNewProduct] = useState({
    name: "",
    soldPrice: "",
    image: null,
    ingredients: [],
  });
  const [editingProduct, setEditingProduct] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fetchedProducts, fetchedIngredients] = await Promise.all([
          Product.getAllProducts(restaurantId),
          Ingredient.getAllIngredientsByRestaurant(restaurantId)
        ]);
        setProducts(fetchedProducts);
        setIngredients(fetchedIngredients);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, [restaurantId]);

  const handleDeleteProduct = async (id) => {
    try {
      await Product.deleteProduct(id);
      setProducts(products.filter((product) => product.id !== id));
    } catch (error) {
      console.error("Error deleting product:", error.message);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewProduct(prev => ({ ...prev, image: file }));
      setImagePreview(URL.createObjectURL(file));
    }
  };
  const calculateProductCost = (ingredients, productIngredients) => {
    return productIngredients.reduce((totalCost, productIngredient) => {
      const ingredient = ingredients.find(
        (ing) => ing.id === productIngredient.ingredientId
      );
      return ingredient
        ? totalCost + ingredient.price * productIngredient.quantity
        : totalCost;
    }, 0);
  };

  const handleSubmitProduct = async () => {
   
      try {
        const productCost = calculateProductCost(
          ingredients,
          newProduct.ingredients
        );
        const product = new Product(
          newProduct.name,
          parseFloat(newProduct.soldPrice),
          restaurantId,
          newProduct.image,
          newProduct.ingredients
        );
        await product.addProduct();
        setProducts([...products, product]);
        setNewProduct({ name: "", soldPrice: "", image: null, ingredients: [] });
        setOpenDialog(false);
      } catch (error) {
        console.error("Error adding product:", error.message);
      }
  };
  const handleSaveProduct = async () => {
    try {
      const productCost = calculateProductCost(ingredients, newProduct.ingredients);
  
      const updatedProduct = new Product(
        newProduct.name,
        parseFloat(newProduct.soldPrice),
        restaurantId,
        newProduct.image,
        newProduct.ingredients
      );
  
      await Product.updateProduct(editingProduct.id, updatedProduct);
  
      setProducts(
        products.map((product) =>
          product.id === editingProduct.id
            ? { ...product, ...updatedProduct, cost: productCost }
            : product
        )
      );
  
      setEditingProduct(null);
      setNewProduct({ name: "", soldPrice: "", image: null, ingredients: [] });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error saving product:", error.message);
    }
  };

  const resetForm = () => {
    setNewProduct({ name: "", soldPrice: "", image: null, ingredients: [] });
    setImagePreview(null);
    setEditingProduct(null);
    setOpenDialog(false);
  };

  const handleIngredientChange = (ingredientId, field, value) => {
    setNewProduct(prev => ({
      ...prev,
      ingredients: prev.ingredients.map(ing =>
        ing.ingredientId === ingredientId ? { ...ing, [field]: value } : ing
      )
    }));
  };

  const handleSelectIngredients = (selectedIds) => {
    const newIngredients = selectedIds.map(id => ({
      ingredientId: id,
      quantity: newProduct.ingredients.find(ing => ing.ingredientId === id)?.quantity || 0
    }));
    setNewProduct(prev => ({ ...prev, ingredients: newIngredients }));
  };

  const handleGenerateMenu = () => {
    setMenuDialogOpen(true);
    const initialRatings = {};
    products.forEach(product => {
      initialRatings[product.id] = product.rating || 0;
    });
    setRatings(initialRatings);
  };

  const handleRatingChange = (productId, newValue) => {
    setRatings(prev => ({
      ...prev,
      [productId]: newValue
    }));
  };

  const ProductCard = ({ product, onEdit, onDelete }) => {
    const cost = calculateProductCost(ingredients, product.ingredients);
    const profit = product.soldPrice - cost;
    const profitPercentage = (profit / product.soldPrice * 100).toFixed(1);

    return (
      <Card sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        transition: 'transform 0.2s',
        '&:hover': { transform: 'translateY(-5px)' }
      }}>
        <CardMedia
          component="img"
          height="200"
          image={product.image || "https://via.placeholder.com/150"}
          alt={product.name}
          sx={{ objectFit: 'cover' }}
        />
        
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            {product.name}
          </Typography>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Chip 
              label={`Price: ${product.soldPrice} TND`} 
              color="primary" 
              variant="outlined"
            />
            <Chip 
              label={`Cost: ${cost.toFixed(2)} TND`}
              color={profit >= 0 ? 'success' : 'error'}
              variant="outlined"
            />
          </Box>

          <Divider sx={{ my: 2 }} />

          <Typography variant="body2" color="text.secondary" gutterBottom>
            Ingredients:
          </Typography>
          
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {product.ingredients.map(({ ingredientId, quantity }) => {
              const ingredient = ingredients.find(ing => ing.id === ingredientId);
              return (
                <Chip
                  key={ingredientId}
                  label={`${ingredient?.name || 'Unknown'} - ${quantity}`}
                  size="small"
                  avatar={<Avatar>{ingredient?.unit?.charAt(0) || '?'}</Avatar>}
                />
              );
            })}
          </Box>
        </CardContent>

        <CardActions sx={{ justifyContent: 'space-between', p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body2" color={profit >= 0 ? 'success.main' : 'error.main'}>
              {profit >= 0 ? 'Profit' : 'Loss'}: {Math.abs(profit).toFixed(2)} TND ({profitPercentage}%)
            </Typography>
          </Box>
          
          <Box>
            <IconButton onClick={onEdit} color="primary">
              <Edit />
            </IconButton>
            <IconButton onClick={onDelete} color="error">
              <Delete />
            </IconButton>
          </Box>
        </CardActions>
      </Card>
    );
  };

  return (
    <Box sx={{ p: 3, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      <Box sx={{ 
        maxWidth: 1200, 
        mx: 'auto', 
        p: 3, 
        bgcolor: 'white', 
        borderRadius: 4, 
        boxShadow: 3 
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 4 
        }}>
          <Typography variant="h4" sx={{ 
            fontWeight: 'bold', 
            color: 'primary.main',
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}>
            <Fastfood fontSize="large" /> Food Cost Manager
          </Typography>
          
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleGenerateMenu}
            >
              Generate Menu
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate("/ingredients")}
            >
              Manage Ingredients
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              New Dish
            </Button>
          </Box>
        </Box>

        <ExcelTableFoodCost data={products} ingredients={ingredients} />

        <Grid container spacing={3} sx={{ mt: 2 }}>
          {products.map(product => (
            <Grid item xs={12} sm={6} md={4} key={product.id}>
              <ProductCard 
                product={product}
                onEdit={() => {
                  setEditingProduct(product);
                  setNewProduct(product);
                  setImagePreview(product.image);
                  setOpenDialog(true);
                }}
                onDelete={() => handleDeleteProduct(product.id)}
              />
            </Grid>
          ))}
        </Grid>

        <Dialog open={openDialog} onClose={resetForm} maxWidth="md" fullWidth>
          <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
            {editingProduct ? 'Edit Dish' : 'Create New Dish'}
          </DialogTitle>
          
          <DialogContent sx={{ py: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box sx={{ 
                  border: '2px dashed #ccc', 
                  borderRadius: 2, 
                  p: 2, 
                  textAlign: 'center',
                  height: 300,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}>
                  {imagePreview ? (
                    <img 
                      src={imagePreview} 
                      alt="Preview" 
                      style={{ 
                        maxHeight: '100%', 
                        maxWidth: '100%', 
                        objectFit: 'contain' 
                      }} 
                    />
                  ) : (
                    <>
                      <AddAPhoto fontSize="large" color="action" />
                      <Typography color="textSecondary">
                        Upload Dish Photo (Recommended)
                      </Typography>
                    </>
                  )}
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ mt: 2 }}
                    startIcon={<AddAPhoto />}
                  >
                    Choose Image
                    <input type="file" hidden onChange={handleImageUpload} />
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Dish Name"
                  value={newProduct.name}
                  onChange={e => setNewProduct({ ...newProduct, name: e.target.value })}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Selling Price (TND)"
                  type="number"
                  value={newProduct.soldPrice}
                  onChange={e => setNewProduct({ ...newProduct, soldPrice: e.target.value })}
                  sx={{ mb: 3 }}
                />

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Select Ingredients</InputLabel>
                  <Select
                    multiple
                    value={newProduct.ingredients.map(ing => ing.ingredientId)}
                    onChange={e => handleSelectIngredients(e.target.value)}
                    renderValue={selected => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map(id => (
                          <Chip 
                            key={id} 
                            label={ingredients.find(ing => ing.id === id)?.name} 
                            size="small" 
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {ingredients.map(ingredient => (
                      <MenuItem key={ingredient.id} value={ingredient.id}>
                        <Checkbox checked={newProduct.ingredients.some(
                          ing => ing.ingredientId === ingredient.id
                        )} />
                        <ListItemText primary={ingredient.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Divider sx={{ my: 2 }} />

                <Typography variant="subtitle1" gutterBottom>
                  Ingredient Quantities:
                </Typography>

                {newProduct.ingredients.map(({ ingredientId, quantity }) => {
                  const ingredient = ingredients.find(ing => ing.id === ingredientId);
                  return (
                    <TextField
                      key={ingredientId}
                      fullWidth
                      label={`${ingredient?.name} Quantity (${ingredient?.unit})`}
                      type="number"
                      value={quantity}
                      onChange={e => handleIngredientChange(
                        ingredientId, 
                        'quantity', 
                        parseFloat(e.target.value)
                      )}
                      sx={{ mb: 2 }}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions sx={{ p: 3 }}>
            <Button 
              onClick={resetForm} 
              color="error" 
              startIcon={<Cancel />}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button 
              onClick={handleSubmitProduct} 
              color="primary" 
              startIcon={<Save />}
              variant="contained"
            >
              {editingProduct ? 'Save Changes' : 'Create Dish'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={menuDialogOpen} onClose={() => setMenuDialogOpen(false)} maxWidth="md" fullWidth>
          <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
            Restaurant Menu
          </DialogTitle>
          <DialogContent sx={{ py: 3 }}>
            <Box sx={{ 
              backgroundColor: '#fff9f2', 
              p: 3, 
              borderRadius: 2,
              fontFamily: 'cursive'
            }}>
              <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 4 }}>
                Our Special Menu
              </Typography>
              
              {products.map(product => (
                <Box key={product.id} sx={{ mb: 4, borderBottom: '1px dashed #ddd', pb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CardMedia
                      component="img"
                      image={product.image || "https://via.placeholder.com/150"}
                      alt={product.name}
                      sx={{ width: 100, height: 100, borderRadius: 2 }}
                    />
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" fontWeight="bold">
                        {product.name}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        Price: {product.soldPrice} TND
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Rating
                          name={`rating-${product.id}`}
                          value={ratings[product.id] || 0}
                          onChange={(event, newValue) => handleRatingChange(product.id, newValue)}
                        />
                        <Typography variant="body2">
                          ({ratings[product.id]?.toFixed(1) || 0}/5)
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  
                  <Typography variant="body2" sx={{ mt: 1, color: '#666' }}>
                    Ingredients: {product.ingredients.map(ing => 
                      ingredients.find(i => i.id === ing.ingredientId)?.name
                    ).join(', ')}
                  </Typography>
                </Box>
              ))}
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button 
              onClick={() => setMenuDialogOpen(false)} 
              color="primary"
              variant="contained"
            >
              Close Menu
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default FoodCostPage;
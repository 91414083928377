import React, { useState, useEffect } from "react";
import ExcelTable4 from "../Components/TableExcel/TableExcel";
import Encaissement from "../Services/Encaisement";
import {
  Card,
  CardContent,
  Typography,
  Tooltip,
  TextField,
  Button,
  TableContainer,
  Paper,
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  useTheme,
} from "@mui/material";

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  DateRange as DateRangeIcon,
  AttachMoney as MoneyIcon,
} from "@mui/icons-material";
const EncaissementsPage = () => {
  const theme = useTheme();
  const [encaissements, setEncaissements] = useState([]);
  const [newEncaissement, setNewEncaissement] = useState({
    value: "",
    type: "",
    date: new Date().toISOString().split("T")[0],
    customType: "",
  });
  const [editingEncaissement, setEditingEncaissement] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchType, setSearchType] = useState("");
  const restaurantId = localStorage.getItem("restaurantId");
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedEncaissements = await Encaissement.getEncaissementsByRestaurantId(restaurantId);
        setEncaissements(fetchedEncaissements);
      } catch (error) {
        console.error("Error fetching encaissements:", error.message);
      }
    };
    if (restaurantId) {
      fetchData();
    }
  }, [restaurantId]);

  const filteredEncaissements = encaissements.filter((encaissement) => {
    const typeFilter = searchType ? encaissement.type === searchType : true;
    const dateDebutFilter = dateDebut ? new Date(encaissement.date) >= new Date(dateDebut) : true;
    const dateFinFilter = dateFin ? new Date(encaissement.date) <= new Date(dateFin) : true;
    return typeFilter && dateDebutFilter && dateFinFilter;
  });


  // Handlers
  const handleAddEncaissement = async () => {
    if (!newEncaissement.value || !newEncaissement.type || !newEncaissement.date) {
      alert("Please fill in all fields");
      return;
    }
    try {
      const encaissement = new Encaissement(
        parseFloat(newEncaissement.value),
        newEncaissement.type === "Other" ? newEncaissement.customType : newEncaissement.type,
        newEncaissement.date,
        restaurantId
      );
      await encaissement.addEncaissement();
      setEncaissements([...encaissements, encaissement]);
      setNewEncaissement({ value: "", type: "", date: new Date().toISOString().split("T")[0], customType: "" });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error adding encaissement:", error.message);
    }
  };

  const handleEditEncaissement = (encaissement) => {
    setEditingEncaissement(encaissement);
    setNewEncaissement({
      value: encaissement.value,
      type: encaissement.type,
      date: encaissement.date,
      customType: encaissement.customType || "", // preserve customType if it exists
    });
    setOpenDialog(true);
  };

  const handleSaveEdit = async () => {
    try {
      const updates = {
        value: newEncaissement.value,
        type: newEncaissement.type === "Other" ? newEncaissement.customType : newEncaissement.type,
        date: newEncaissement.date,
      };
      await Encaissement.updateEncaissement(editingEncaissement.id, updates);
      setEncaissements((prev) =>
        prev.map((enc) =>
          enc.id === editingEncaissement.id ? { ...enc, ...updates } : enc
        )
      );
      setEditingEncaissement(null);
      setNewEncaissement({ value: "", type: "", date: new Date().toISOString().split("T")[0], customType: "" });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error saving edit:", error.message);
    }
  };

  const handleDeleteEncaissement = async (id) => {
    try {
      await Encaissement.deleteEncaissement(id);
      setEncaissements((prev) => prev.filter((encaissement) => encaissement.id !== id));
    } catch (error) {
      console.error("Error deleting encaissement:", error.message);
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

 
  const PageContainer = ({ children }) => (
    <Box sx={{ 
      p: 4, 
      minHeight: '100vh',
      background: theme.palette.background.default,
    }}>
      {children}
    </Box>
  );

  const SectionCard = ({ children, title, subtitle }) => (
    <Card sx={{ 
      mb: 4, 
      borderRadius: 2,
      boxShadow: theme.shadows[3],
      transition: 'all 0.3s ease',
      '&:hover': {
        boxShadow: theme.shadows[6],
        transform: 'translateY(-2px)'
      }
    }}>
      <CardContent>
        {title && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: 600, color: theme.palette.primary.dark }}>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="body2" color="textSecondary">
                {subtitle}
              </Typography>
            )}
          </Box>
        )}
        {children}
      </CardContent>
    </Card>
  );

  // Enhanced Table Components
  const EnhancedTableHead = () => (
    <TableHead sx={{ bgcolor: theme.palette.primary.light }}>
      <TableRow>
        {['Date', 'Amount', 'Payment Type', 'Actions'].map((header) => (
          <TableCell key={header} sx={{ 
            fontWeight: 600, 
            color: theme.palette.getContrastText(theme.palette.primary.light),
            py: 2
          }}>
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const TransactionRow = ({ enc }) => (
    <TableRow hover sx={{ '&:nth-of-type(odd)': { bgcolor: 'action.hover' } }}>
      <TableCell sx={{ fontWeight: 500 }}>
        {new Date(enc.date).toLocaleDateString()}
      </TableCell>
      <TableCell sx={{ color: theme.palette.success.dark, fontWeight: 600 }}>
        <MoneyIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'middle' }} />
        {enc.value} TND
      </TableCell>
      <TableCell>
        <Box sx={{
          display: 'inline-flex',
          alignItems: 'center',
          px: 2,
          py: 1,
          borderRadius: 1,
          bgcolor: theme.palette.secondary.light,
          color: theme.palette.secondary.contrastText,
          fontSize: 14
        }}>
          {enc.type}
        </Box>
      </TableCell>
      <TableCell>
        <IconButton onClick={() => handleEditEncaissement(enc)} size="small">
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={() => handleDeleteEncaissement(enc.id)} size="small">
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  return (
    <PageContainer>
      {/* Header Section */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 4
      }}>
        <Box>
          <Typography variant="h4" sx={{ 
            fontWeight: 700, 
            color: theme.palette.primary.main,
            mb: 1
          }}>
            Cash Management
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Track and manage financial transactions
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
          sx={{
            height: 40,
            px: 3,
            borderRadius: 2,
            bgcolor: 'primary.main',
            '&:hover': { bgcolor: 'primary.dark' }
          }}
        >
          New Entry
        </Button>
      </Box>

      {/* Filters Section */}
      <SectionCard title="Filters" subtitle="Filter transactions by criteria">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Payment Type</InputLabel>
              <Select
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
                label="Payment Type"
              >
                <MenuItem value="">All Types</MenuItem>
                {[...new Set(encaissements.map(e => e.type))].map(type => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              size="small"
              value={dateDebut}
              onChange={(e) => setDateDebut(e.target.value)}
              InputProps={{
                startAdornment: <DateRangeIcon sx={{ color: 'action.active', mr: 1 }} />,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="End Date"
              type="date"
              size="small"
              value={dateFin}
              onChange={(e) => setDateFin(e.target.value)}
              InputProps={{
                startAdornment: <DateRangeIcon sx={{ color: 'action.active', mr: 1 }} />,
              }}
            />
          </Grid>
        </Grid>
      </SectionCard>

      {/* Data Section */}
      <SectionCard title="Transaction Overview" subtitle="List of all financial transactions">
        <TableContainer>
          <Table size="small">
            <EnhancedTableHead />
            <TableBody>
              {filteredEncaissements
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(enc => <TransactionRow key={enc.id} enc={enc} />)}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredEncaissements.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </SectionCard>

      {/* Transaction Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ 
          bgcolor: 'primary.main', 
          color: 'primary.contrastText',
          py: 2
        }}>
          {editingEncaissement ? 'Edit Transaction' : 'Create New Transaction'}
        </DialogTitle>
        <DialogContent sx={{ py: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Amount (TND)"
                variant="outlined"
                size="small"
                type="number"
                InputProps={{
                  startAdornment: <MoneyIcon sx={{ color: 'action.active', mr: 1 }} />
                }}
                value={newEncaissement.value}
                onChange={(e) => setNewEncaissement({ ...newEncaissement, value: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel>Transaction Type</InputLabel>
                <Select
                  value={newEncaissement.type}
                  onChange={(e) => setNewEncaissement({
                    ...newEncaissement,
                    type: e.target.value,
                    customType: e.target.value === "Other" ? newEncaissement.customType : "",
                  })}
                  label="Transaction Type"
                >
                  <MenuItem value="">Select Type</MenuItem>
                  {[...new Set(encaissements.map(enc => enc.type))].map(type => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {newEncaissement.type === "Other" && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Custom Type"
                  variant="outlined"
                  size="small"
                  value={newEncaissement.customType}
                  onChange={(e) => setNewEncaissement({ ...newEncaissement, customType: e.target.value })}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Transaction Date"
                type="date"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={newEncaissement.date}
                onChange={(e) => setNewEncaissement({ ...newEncaissement, date: e.target.value })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button 
            onClick={() => setOpenDialog(false)}
            variant="outlined" 
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={editingEncaissement ? handleSaveEdit : handleAddEncaissement}
            variant="contained"
            size="small"
            color="primary"
          >
            {editingEncaissement ? 'Save Changes' : 'Create Transaction'}
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default EncaissementsPage;
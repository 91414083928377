import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../Firebase/Config";  // Ensure the Firebase config is correctly imported
import { getFirestore } from "firebase/firestore"; // Firestore imports
import User from "../Services/User"; // Ensure the User model is imported

const db = getFirestore(); // Get Firestore instance

class AuthController {
  static async handleSignup(email, password, profilePic, role, isVerified, payment, num, nom, prenom) {
    const user = new User(email, password, role, num, nom, prenom, isVerified, payment, profilePic);
    
    await user.signup();
    console.log("User signed up and additional details stored successfully.");
  }

  static async handleLogin(email, password) {
    try {
      const user = new User(email, password);
      const userData = await user.login();

      if (userData) {
        localStorage.setItem("user", JSON.stringify(userData));
        console.log("User logged in and stored in localStorage.");
      } else {
        throw new Error("Invalid login credentials.");
      }
    } catch (error) {
      throw new Error("Login failed: " + error.message);
    }
  }

  static async handleForgotPassword(email) {
    try {
      await sendPasswordResetEmail(auth, email);
      console.log("Password reset email sent.");
    } catch (error) {
      throw new Error("Error sending reset email: " + error.message);
    }
  }
}

export default AuthController;

import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import EncaissementService from "../Services/Encaisement";
import DecaissementService from "../Services/Decaisement";
import RapportX from "../Services/RapportX";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Skeleton,
  useTheme,
  Container,
  Chip,
  Divider,
  Stack
} from "@mui/material";
import ExcelTable from "../Components/TableExcel/VaultTable";
import {
  AccountBalanceWallet,
  ArrowUpward,
  ArrowDownward,
  ListAlt,
  FilterList,
  Autorenew,
  Clear
} from "@mui/icons-material";
import { green, red, blue } from "@mui/material/colors";

const VaultPage = () => {
  const theme = useTheme();
  const [encaissements, setEncaissements] = useState([]);
  const [decaissements, setDecaissements] = useState([]);
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [encaissementsData, decaissementsData] = await Promise.all([
          EncaissementService.getAllEncaissements(),
          DecaissementService.getAllDecaissements()
        ]);
        
        setEncaissements(encaissementsData);
        setDecaissements(decaissementsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const restaurantId = localStorage.getItem("restaurantId");
  const combinedData = useMemo(() => [
    ...encaissements
      .filter(e => e.restaurantId === restaurantId) // Filter encaissements by restaurantId
      .map(e => ({ ...e, category: "ENCAISSEMENT" })),
    ...decaissements
      .filter(d => d.restaurantId === restaurantId) // Filter decaissements by restaurantId
      .map(d => ({ ...d, category: "DECAISSEMENT" }))
  ], [encaissements, decaissements, restaurantId]);
 



const totalRapportX = (Array.isArray(RapportX) ? RapportX : [])
  .filter(r => r.restaurantId === restaurantId)
  .reduce((sum, r) => sum + (r.value || 0), 0);

console.log("Total Rapport X:", totalRapportX);

  


  useEffect(() => {
    const filtered = combinedData.filter(item => {
      const itemDate = new Date(item.date);
      const start = dateDebut ? new Date(dateDebut) : null;
      const end = dateFin ? new Date(dateFin) : null;
      
      return (!start || itemDate >= start) && 
             (!end || itemDate <= end) && 
             (!filterType || item.category === filterType);
    });
    
    setFilteredData(filtered);
  }, [combinedData, dateDebut, dateFin, filterType]);

  const totalEncaissements = (Array.isArray(encaissements) ? encaissements : [])
    .filter(e => e.restaurantId === restaurantId) // Filter by restaurantId
    .reduce((sum, e) => sum + (e.value || 0), 0);
  
  const totalDecaissements = (Array.isArray(decaissements) ? decaissements : [])
    .filter(d => d.restaurantId === restaurantId) // Filter by restaurantId
    .reduce((sum, d) => sum + (d.value || 0), 0);
  
  const solde = totalEncaissements + totalDecaissements;
  
  console.log("Total Encaissements:", totalEncaissements);
  console.log("Total Decaissements:", totalDecaissements);
  console.log("Solde:", solde);
  

  const clearFilters = () => {
    setDateDebut("");
    setDateFin("");
    setFilterType("");
  };

  return (
    <Container maxWidth="xl" sx={{ p: 2, bgcolor: 'background.default', minHeight: '100vh' ,width:"100%",overflowX:"hidden",maxWidth:"100vw"}}>
      {/* Header Section */}
      <Box sx={{ mb: 4, textAlign: 'center' }}>
        <AccountBalanceWallet sx={{ 
          fontSize: 60, 
          color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main,
          mb: 1
        }}/>
        <Typography variant="h3" sx={{ 
          fontWeight: 700, 
          color: 'text.primary',
          mb: 1
        }}>
          Gestion de Trésorerie
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
          Suivi en temps réel des flux financiers
        </Typography>
      </Box>

      {/* Quick Stats */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Encaissements"
            value={`${Number(totalEncaissements).toFixed(3)} TND`}
            icon={<ArrowUpward />}
            color={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Décaissements"
            value={`${Number(totalDecaissements).toFixed(3)} TND`}
            icon={<ArrowDownward />}
            color={red[500]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Chiffre d'affaire réel"
            value={`${Number(solde).toFixed(3)} TND`}
            icon={<AccountBalanceWallet />}
            color={blue[500]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="chiffre d'affaire Theorique"

            value={totalRapportX}
            icon={<ListAlt />}
            color={theme.palette.secondary.main}
          />
        </Grid>
      </Grid>
{/* Quick Actions */}
<Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={4}>
          <ActionCard
            title="Nouvel Encaissement"
            icon={<ArrowUpward />}
            color={green[500]}
            to="/Encaissement"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ActionCard
            title="Nouveau Décaissement"
            icon={<ArrowDownward />}
            color={red[500]}
            to="/Decaissement"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ActionCard
            title="Historique Complet"
            icon={<ListAlt />}
            color={blue[500]}
            to="/AllVault"
          />
        </Grid>
      </Grid>
      {/* Filters Section */}
      <Paper sx={{ 
        p: 3, 
        mb: 4, 
        borderRadius: 4, 
        bgcolor: 'background.paper',
        boxShadow: theme.shadows[2]
      }}>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
          <FilterList color="primary" />
          <Typography variant="h6" sx={{ fontWeight: 600 }}>Filtres</Typography>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Date de début"
              type="date"
              value={dateDebut}
              onChange={(e) => setDateDebut(e.target.value)}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Date de fin"
              type="date"
              value={dateFin}
              onChange={(e) => setDateFin(e.target.value)}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Type de transaction</InputLabel>
              <Select
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
                label="Type de transaction"
              >
                <MenuItem value="">Tous</MenuItem>
                <MenuItem value="ENCAISSEMENT">Encaissements</MenuItem>
                <MenuItem value="DECAISSEMENT">Décaissements</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              fullWidth
              variant="outlined"
              onClick={clearFilters}
              startIcon={<Clear />}
              sx={{ height: 40 }}
            >
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Data Section */}
      <Paper sx={{ 
        p: 2, 
        borderRadius: 4, 
        bgcolor: 'background.paper',
        boxShadow: theme.shadows[2]
      }}>
        {loading ? (
          <Skeleton variant="rectangular" height={400} sx={{ borderRadius: 3 }} />
        ) : (
          <>
            <Stack direction="row" spacing={1} sx={{ mb: 2, p: 1 }}>
              <Chip 
                label={`Encaissements: ${filteredData.filter(d => d.category === 'ENCAISSEMENT').length}`} 
                color="success" 
                variant="outlined"
              />
              <Chip 
                label={`Décaissements: ${filteredData.filter(d => d.category === 'DECAISSEMENT').length}`} 
                color="error" 
                variant="outlined"
              />
            </Stack>
            
            <Box sx={{ 
              height: '60vh',
              width: '100%',
              overflowX: 'hidden',
              '& .MuiDataGrid-root': {
                border: 'none',
                '& .MuiDataGrid-columnHeaders': {
                  bgcolor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.03)',
                }
              }
            }}>
              <ExcelTable data={filteredData} />
            </Box>
          </>
        )}
      </Paper>

      
    </Container>
  );
};

const StatCard = ({ title, value, icon, color }) => (
  <Card sx={{ 
    height: '100%', 
    borderRadius: 3, 
    bgcolor: 'background.paper',
    transition: '0.3s',
    '&:hover': { transform: 'translateY(-4px)' }
  }}>
    <CardContent>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box sx={{ 
          p: 1.5, 
          bgcolor: color + '10', 
          borderRadius: '50%',
          lineHeight: 0
        }}>
          {React.cloneElement(icon, { sx: { fontSize: 28, color } })}
        </Box>
        <Box>
          <Typography variant="subtitle2" color="text.secondary">{title}</Typography>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>{value}</Typography>
        </Box>
      </Stack>
    </CardContent>
  </Card>
);

const ActionCard = ({ title, icon, color, to }) => (
  <Button
    component={Link}
    to={to}
    fullWidth
    sx={{
      p: 3,
      height: '100%',
      borderRadius: 3,
      bgcolor: 'background.paper',
      textAlign: 'left',
      transition: '0.3s',
      '&:hover': { 
        transform: 'translateY(-4px)',
        bgcolor: 'action.hover'
      }
    }}
  >
    <Stack spacing={2} alignItems="flex-start">
      <Box sx={{ 
        p: 1.5, 
        bgcolor: color + '10', 
        borderRadius: '50%',
        lineHeight: 0
      }}>
        {React.cloneElement(icon, { sx: { fontSize: 28, color } })}
      </Box>
      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{title}</Typography>
      <Typography variant="body2" color="text.secondary" sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 0.5
      }}>
        <Autorenew fontSize="small" />
        Accès rapide
      </Typography>
    </Stack>
  </Button>
);

export default VaultPage;
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  IconButton,
  CircularProgress,
  Box,
  Typography
} from '@mui/material';
import { Add, Edit, Delete, Inventory2, Close } from '@mui/icons-material';
import { Alert } from '@mui/material';
import Stock from '../Services/Stock';

const StockScreen = () => {
  const [stockList, setStockList] = useState([]);
  const [stockData, setStockData] = useState({
    productName: '',
    pricePerUnit: '',
    quantity: '',
  });
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [deleteConfirm, setDeleteConfirm] = useState(null);

  const fetchStocks = async () => {
    try {
      const stocks = await Stock.getAllStocks();
      setStockList(stocks);
    } catch (error) {
      showSnackbar('Error fetching stocks', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStockData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { productName, pricePerUnit, quantity } = stockData;
      const stock = new Stock(productName, parseFloat(pricePerUnit), new Date(), parseInt(quantity));

      if (deleteConfirm) {
        await stock.updateStock(deleteConfirm, stockData);
        showSnackbar('Stock updated successfully', 'success');
      } else {
        await stock.addStock();
        showSnackbar('Stock added successfully', 'success');
      }

      setStockData({ productName: '', pricePerUnit: '', quantity: '' });
      setOpenDialog(false);
      fetchStocks();
    } catch (error) {
      showSnackbar(error.message || 'Operation failed', 'error');
    }
  };

  const handleDelete = async (stockId) => {
    try {
      const stock = new Stock();
      await stock.deleteStock(stockId);
      showSnackbar('Stock deleted successfully', 'success');
      fetchStocks();
    } catch (error) {
      showSnackbar('Error deleting stock', 'error');
    } finally {
      setDeleteConfirm(null);
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  useEffect(() => { fetchStocks(); }, []);

  return (
    <Box sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Stock Management
        </Typography>
        <Box>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setOpenDialog(true)}
            sx={{ mr: 2 }}
          >
            Add Stock
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Inventory2 />}
            onClick={() => showSnackbar('Inventory request sent!', 'info')}
          >
            Request Inventory
          </Button>
        </Box>
      </Box>

      {/* Add/Edit Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{deleteConfirm ? 'Edit Stock' : 'Add New Stock'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Product Name"
              name="productName"
              value={stockData.productName}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="Price per Unit"
              name="pricePerUnit"
              value={stockData.pricePerUnit}
              onChange={handleInputChange}
              required
              type="number"
              fullWidth
              margin="normal"
            />
            <TextField
              label="Quantity"
              name="quantity"
              value={stockData.quantity}
              onChange={handleInputChange}
              required
              type="number"
              fullWidth
              margin="normal"
            />
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
              <Button type="submit" variant="contained">
                {deleteConfirm ? 'Update' : 'Add'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={Boolean(deleteConfirm)} onClose={() => setDeleteConfirm(null)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this stock item?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirm(null)}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(deleteConfirm)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Stock List Table */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ borderRadius: 2, overflowX: 'auto' }}>
          <Table>
            <TableHead sx={{ bgcolor: 'primary.main' }}>
              <TableRow>
                {['Product', 'Price/Unit', 'Date', 'Quantity', 'Total', 'Actions'].map((header) => (
                  <TableCell key={header} sx={{ color: 'white', fontWeight: 'bold' }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stockList.map((stock) => (
                <TableRow key={stock.id} hover>
                  <TableCell>{stock.productName}</TableCell>
                  <TableCell>${parseFloat(stock.pricePerUnit).toFixed(2)}</TableCell>
                  <TableCell>
                    {new Date(stock.date.seconds * 1000).toLocaleString('fr-FR')}
                  </TableCell>
                  <TableCell>{stock.quantity}</TableCell>
                  <TableCell>
                    ${(stock.pricePerUnit * stock.quantity).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setStockData({
                          productName: stock.productName,
                          pricePerUnit: stock.pricePerUnit,
                          quantity: stock.quantity,
                        });
                        setDeleteConfirm(stock.id);
                        setOpenDialog(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => setDeleteConfirm(stock.id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Snackbar Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default StockScreen;
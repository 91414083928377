import { getFirestore, doc, setDoc, deleteDoc, collection, getDocs } from "firebase/firestore";
const db = getFirestore();

class Stock {
  constructor(productName, pricePerUnit, date, quantity) {
    this.productName = productName;
    this.pricePerUnit = pricePerUnit;
    this.date = date;
    this.quantity = quantity;
  }

  async addStock() {
    try {
      const stockRef = doc(collection(db, "stocks"));
      await setDoc(stockRef, {
        productName: this.productName,
        pricePerUnit: this.pricePerUnit,
        date: this.date,
        quantity: this.quantity,
      });
      console.log('Stock added successfully!');
    } catch (error) {
      console.error('Error adding stock:', error.message);
    }
  }

  async updateStock(stockId, updatedData) {
    try {
      const stockRef = doc(db, "stocks", stockId);
      await setDoc(stockRef, updatedData, { merge: true });
      console.log('Stock updated successfully!');
    } catch (error) {
      console.error('Error updating stock:', error.message);
    }
  }

  async deleteStock(stockId) {
    try {
      const stockRef = doc(db, "stocks", stockId);
      await deleteDoc(stockRef);
      console.log('Stock deleted successfully!');
    } catch (error) {
      console.error('Error deleting stock:', error.message);
    }
  }

  static async getAllStocks() {
    const stocksRef = collection(db, "stocks");
    const stockDocs = await getDocs(stocksRef);
    const stockList = [];
    stockDocs.forEach((doc) => {
      stockList.push({ id: doc.id, ...doc.data() });
    });
    return stockList;
  }
}

export default Stock;

import React, { useState, useEffect,useRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
  FormControl,
  
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Close, Add, Edit, Delete, Print, Save } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { auth } from "../Firebase/Config";
// Styled Components
const StyledFormCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: 16,
  boxShadow: theme.shadows[10],
  maxWidth: 800,
  margin: 'auto',
  background: 'linear-gradient(145deg, #f5f5f5, #ffffff)',
}));

const StyledInvoiceCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: 16,
  boxShadow: theme.shadows[10],
  maxWidth: 1000,
  margin: 'auto',
  background: '#ffffff',
}));

const TotalRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  '& td': {
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
}));

// ClientForm Component
const ClientForm = ({ clientData, handleChange, handleSubmit }) => {
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!clientData.name) newErrors.name = 'Nom du client requis';
    if (!clientData.address) newErrors.address = 'Adresse requise';
    if (!clientData.taxNumber) newErrors.taxNumber = 'Numéro de TVA requis';
    if (!clientData.invoiceNumber) newErrors.invoiceNumber = 'Numéro de facture requis';
    if (!clientData.date) newErrors.date = 'Date requise';
    if (!clientData.tva) newErrors.tva = 'TVA requise';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitWithValidation = () => {
    if (validateForm()) handleSubmit();
  };

  return (
    <StyledFormCard>
      <Typography variant="h4" color="primary" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
        🚀 Nouvelle Facture
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Nom du Client"
            variant="outlined"
            fullWidth
            name="name"
            value={clientData.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
            InputProps={{
              startAdornment: <InputAdornment position="start">👤</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Numéro de Facture"
            variant="outlined"
            fullWidth
            name="invoiceNumber"
            value={clientData.invoiceNumber}
            onChange={handleChange}
            error={!!errors.invoiceNumber}
            helperText={errors.invoiceNumber}
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Adresse"
            variant="outlined"
            fullWidth
            name="address"
            value={clientData.address}
            onChange={handleChange}
            error={!!errors.address}
            helperText={errors.address}
            InputProps={{
              startAdornment: <InputAdornment position="start">🏠</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Numéro de TVA"
            variant="outlined"
            fullWidth
            name="taxNumber"
            value={clientData.taxNumber}
            onChange={handleChange}
            error={!!errors.taxNumber}
            helperText={errors.taxNumber}
            InputProps={{
              startAdornment: <InputAdornment position="start">📋</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Date"
            type="date"
            variant="outlined"
            fullWidth
            name="date"
            value={clientData.date || new Date().toISOString().split('T')[0]}
            onChange={handleChange}
            error={!!errors.date}
            helperText={errors.date}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined" error={!!errors.tva}>
            <InputLabel>TVA</InputLabel>
            <Select
              label="TVA"
              name="tva"
              value={clientData.tva}
              onChange={handleChange}
            >
              <MenuItem value={19}>19%</MenuItem>
              <MenuItem value={7}>7%</MenuItem>
              <MenuItem value={0}>0%</MenuItem>
            </Select>
            {errors.tva && <Typography variant="caption" color="error">{errors.tva}</Typography>}
          </FormControl>
        </Grid>
      </Grid>
      <Box textAlign="center" mt={4}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<Save />}
          onClick={handleSubmitWithValidation}
          sx={{ px: 6, py: 1.5, borderRadius: 2 }}
        >
          Continuer vers la Facture
        </Button>
      </Box>
    </StyledFormCard>
  );
};

// InvoicePreview Component
const InvoicePreview = ({ clientData, productList, printInvoice, setOpenProductDialog, editClient }) => {
  const sumHT = productList.reduce((sum, item) => sum + item.totalHT, 0);
  const tvaAmount = sumHT * (clientData.tva / 100);
  const timbre = 1;
  const totalTTC = sumHT + tvaAmount + timbre;
  const restaurantId = localStorage.getItem("restaurantId");
   const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
     const [restaurants, setRestaurants] = useState([]);
 const fetchRestaurants = async () => {
    try {
      setLoading(true);
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error('User not logged in');
      }

      const restaurantsRef = collection(db, 'restaurants');
      const q = query(restaurantsRef, where('ownerId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const restaurantData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setRestaurants(restaurantData);

      // Save the first restaurant ID (or any specific restaurant ID) in localStorage
      if (restaurantData.length > 0) {
        localStorage.setItem('restaurantId', restaurantData[0].id);
        
      }
      
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRestaurants(); // Already fetching restaurants
    fetchData(); // Fetch RapportX data when the page loads
  }, []);  // Empty dependency array ensures this runs on initial load
  
  const fetchData = async () => {
    setLoading(true); // Set loading state before fetching data
    try {
     
      setLoading(false); // Stop loading after data is fetched
    } catch (err) {
      console.error(err);
      setError("Failed to fetch data"); // Handle errors
      setLoading(false);
    }
  };
  return (
    <StyledInvoiceCard>
      {/* Header Section */}
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 4 }}>
        <Grid item>
          <Typography variant="h3" color="primary" sx={{ fontWeight: 'bold' }}>
 <Grid container spacing={3} sx={{ mb: 6 }}>
        {restaurants.map((restaurant) => (
          <Grid item xs={12} sm={6} md={4} key={restaurant.id}>
           
              <Typography variant="h6" fontWeight="600">
                {restaurant.name}
              </Typography>
              <Typography variant="body2">
                {restaurant.address || 'No address available'}
              </Typography>
              <Typography variant="body2">
                {restaurant.phone || 'No phone available'}
              </Typography>
              <Typography variant="body2">
              MF:  {restaurant.matriculeFiscale || 'No phone available'}
              </Typography>
           
          </Grid>
        ))}
      </Grid>
          </Typography>
         
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Edit />}
            onClick={editClient}
            sx={{ mb: 2 }}
          >
            Modifier
          </Button>
          <Typography variant="h5" align="right" sx={{ fontWeight: 'bold' }}>
            Facture N°: {clientData.invoiceNumber}
          </Typography>
          <Typography variant="body2">Date: {new Date(clientData.date).toLocaleDateString()}</Typography>
        </Grid>
      </Grid>

      {/* Client Info */}
      <Box sx={{ p: 3, mb: 4, bgcolor: 'grey.50', borderRadius: 2 }}>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>Client:</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography><strong>Nom:</strong> {clientData.name}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography><strong>Adresse:</strong> {clientData.address}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography><strong>Matricule Fiscal: </strong> {clientData.taxNumber}</Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Products Table */}
      <TableContainer component={Paper} sx={{ mb: 4, border: '1px solid', borderColor: 'divider' }}>
        <Table>
          <TableHead sx={{ bgcolor: 'primary.main' }}>
            <TableRow>
              {['Produit', 'Quantité', 'Unité', 'PU HT', 'Total HT'].map((header) => (
                <TableCell key={header} sx={{ color: 'common.white', fontWeight: 'bold' ,alignItems:'center'}}>
                  {header}
                </TableCell>
              ))}
              <TableCell class="no-print-actions" sx={{ color: 'common.white', fontWeight: 'bold' }}>Actions</TableCell>
    

            </TableRow>
          </TableHead>
          <TableBody>
            {productList.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.product}</TableCell>
                <TableCell align="left">{item.quantity}</TableCell>
                <TableCell align="left">{item.unit}</TableCell>
                <TableCell align="left">TND {item.unitPrice.toFixed(3)}</TableCell>
                <TableCell align="left">TND {item.totalHT.toFixed(3)}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => {/* Add delete functionality */}}>
                    <Delete color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Totals Section */}
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TotalRow>
                  <TableCell>Total HT</TableCell>
                  <TableCell align="right">TND {sumHT.toFixed(3)}</TableCell>
                </TotalRow>
                <TotalRow>
                  <TableCell>TVA ({clientData.tva}%)</TableCell>
                  <TableCell align="right">TND {tvaAmount.toFixed(3)}</TableCell>
                </TotalRow>
                <TotalRow>
                  <TableCell>Timbre</TableCell>
                  <TableCell align="right">TND {timbre.toFixed(3)}</TableCell>
                </TotalRow>
                <TotalRow>
                  <TableCell sx={{ color: 'error.main' }}>Total TTC</TableCell>
                  <TableCell align="right" sx={{ color: 'error.main' }}>
                    TND {totalTTC.toFixed(3)}
                  </TableCell>
                </TotalRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* Action Buttons */}
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => setOpenProductDialog(true)}
          sx={{ px: 6 }}
        >
          Ajouter Produit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Print />}
          onClick={printInvoice}
          sx={{ px: 6 }}
        >
          Imprimer
        </Button>
      </Box>
    </StyledInvoiceCard>
  );
};

// ProductDialog Component
const ProductDialog = ({ open, onClose, product, handleProductChange, addProduct }) => {
  const [errors, setErrors] = useState({});

  const validateProduct = () => {
    const newErrors = {};
    if (!product.product) newErrors.product = 'Nom du produit requis';
    if (product.quantity <= 0) newErrors.quantity = 'Quantité invalide';
    if (product.unitPrice <= 0) newErrors.unitPrice = 'Prix unitaire invalide';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAdd = () => {
    if (validateProduct()) {
      addProduct();
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">➕ Nouveau Produit</Typography>
          <IconButton onClick={onClose}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              label="Nom du Produit"
              fullWidth
              name="product"
              value={product.product}
              onChange={handleProductChange}
              error={!!errors.product}
              helperText={errors.product}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Quantité"
              type="number"
              fullWidth
              name="quantity"
              value={product.quantity}
              onChange={handleProductChange}
              error={!!errors.quantity}
              helperText={errors.quantity}
              inputProps={{ min: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Unité"
              fullWidth
              name="unit"
              value={product.unit}
              onChange={handleProductChange}
              select
            >
              {['pièce', 'kg', 'm', 'l', 'heure'].map((unit) => (
                <MenuItem key={unit} value={unit}>{unit}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Prix Unitaire HT"
              type="number"
              fullWidth
              name="unitPrice"
              value={product.unitPrice}
              onChange={handleProductChange}
              error={!!errors.unitPrice}
              helperText={errors.unitPrice}
              inputProps={{ step: 0.01, min: 0 }}
              InputProps={{
                startAdornment: <InputAdornment position="start">TND</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
        <Box textAlign="center" mt={4}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Save />}
            onClick={handleAdd}
            sx={{ px: 6 }}
          >
            Enregistrer Produit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

// Main Component
const PdfTemplate = () => {
  const ref = useRef();
  const [showForm, setShowForm] = useState(true);
  const [clientData, setClientData] = useState({
    name: '',
    address: '',
    taxNumber: '',
    invoiceNumber: '',
    date: new Date().toISOString().split('T')[0],
    tva: 19,
  });
  const [product, setProduct] = useState({
    product: '',
    quantity: 1,
    unit: 'pièce',
    unitPrice: '',
  });
  const [productList, setProductList] = useState([]);
  const [openProductDialog, setOpenProductDialog] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const handleClientChange = (e) => {
    const { name, value } = e.target;
    setClientData(prev => ({ ...prev, [name]: value }));
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setProduct(prev => ({ ...prev, [name]: value }));
  };
  const fetchRestaurants = async () => {
    try {
      const db = getFirestore();
      const user = auth.currentUser;
      if (!user) return;

      const restaurantsRef = collection(db, 'restaurants');
      const q = query(restaurantsRef, where('ownerId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const restaurantData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRestaurants(restaurantData);
      
      if (restaurantData.length > 0) {
        localStorage.setItem('restaurantId', restaurantData[0].id);
      }
    } catch (err) {
      console.error("Error fetching restaurants:", err);
    }
  };

  useEffect(() => {
    fetchRestaurants();
  }, []);

  const addProduct = () => {
    const newProduct = {
      ...product,
      quantity: parseInt(product.quantity),
      unitPrice: parseFloat(product.unitPrice),
      totalHT: parseInt(product.quantity) * parseFloat(product.unitPrice),
    };
    setProductList(prev => [...prev, newProduct]);
    setProduct({ product: '', quantity: 1, unit: 'pièce', unitPrice: '' });
  };


  const printInvoice = () => {
    // Calculate totals directly from component state
    const sumHT = productList.reduce((sum, item) => sum + item.totalHT, 0);
    const tvaAmount = sumHT * (clientData.tva / 100);
    const timbre = 1;
    const totalTTC = sumHT + tvaAmount + timbre;

    const content = ref.current;
    const printWindow = window.open('', '', 'width=1200,height=800');

    // Get the first restaurant (assuming single restaurant)
    const restaurant = restaurants.length > 0 ? restaurants[0] : {
      name: 'N/A',
      address: 'N/A', 
      phone: 'N/A',
      matriculeFiscale: 'N/A'
    };


    printWindow.document.write(`
        <html>
            <head>
                <title>Facture ${clientData.invoiceNumber}</title>
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
                    body {
                        font-family: 'Poppins', sans-serif;
                        padding: 20px;
                        margin: 0;
                        background: #fff;
                        color: #333;
                    }
                    .invoice-container {
                        max-width: 800px;
                        margin: 20px auto;
                        padding: 30px;
                        background: #ffffff;
                        border-radius: 10px;
                        box-shadow: 0 0 20px rgba(0,0,0,0.1);
                    }
                    .invoice-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-bottom: 20px;
                        border-bottom: 2px solid #7b1fa2;
                    }
                    .company-info {
                        flex: 1;
                    }
                    .company-name {
                        font-size: 24px;
                        color: #4a148c;
                        font-weight: 700;
                        margin-bottom: 5px;
                    }
                    .invoice-title {
                        font-size: 28px;
                        color: #333;
                        text-align: right;
                    }
                    .client-info-section {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 30px;
                        margin: 25px 0;
                        padding: 20px;
                        background: #f8f9fa;
                        border-radius: 8px;
                    }
                    .info-block {
                        padding: 15px;
                    }
                    .info-block h3 {
                        color: #7b1fa2;
                        margin-bottom: 15px;
                        font-size: 18px;
                        border-bottom: 2px solid #eee;
                        padding-bottom: 8px;
                    }
                    .items-table {
                        width: 100%;
                        border-collapse: collapse;
                        margin: 25px 0;
                    }
                    .items-table th {
                        background: #7b1fa2;
                        color: white;
                        padding: 12px;
                        text-align: left;
                        font-weight: 500;
                    }
                    .items-table td {
                        padding: 12px;
                        border-bottom: 1px solid #eee;
                    }
                    .items-table tr:nth-child(even) {
                        background: #f9f9f9;
                    }
                    .total-section {
                        margin-top: 25px;
                        padding: 20px;
                        background: #f8f9fa;
                        border-radius: 8px;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 15px;
                    }
                    .total-line {
                        display: flex;
                        justify-content: space-between;
                        padding: 8px 0;
                    }
                    .grand-total {
                        font-size: 18px;
                        color: #4a148c;
                        font-weight: 700;
                        border-top: 2px solid #7b1fa2;
                        padding-top: 12px;
                    }
                    .payment-info {
                        margin-top: 25px;
                        padding: 20px;
                        background: #fff8e1;
                        border-left: 4px solid #ffb300;
                        border-radius: 4px;
                    }
                    .footer-note {
                        text-align: center;
                        margin-top: 30px;
                        color: #666;
                        font-size: 14px;
                        padding-top: 20px;
                        border-top: 1px solid #eee;
                    }
                </style>
            </head>
            <body>
                <div class="invoice-container">
                    <div class="invoice-header">
                        <div class="company-info">
                            <div class="company-name">${restaurant.id}</div>
                            <div>${restaurant.address}</div>
                            <div>Tél: ${restaurant.phone}</div>
                            <div>MF: ${restaurant.matriculeFiscale}</div>
                        </div>
                        <div class="invoice-title">
                            FACTURE<br>
                            <span style="font-size: 18px;">N° ${clientData.invoiceNumber}</span><br>
                            <span style="font-size: 14px; color: #666;">Date: ${new Date(clientData.date).toLocaleDateString()}</span>
                        </div>
                    </div>

                    <div class="client-info-section">
                        <div class="info-block">
                            <h3>Émetteur</h3>
                            <div><strong>${restaurant.name}</strong></div>
                            <div>${restaurant.address}</div>
                            <div>MF: ${restaurant.matriculeFiscale}</div>
                            <div>Tél: ${restaurant.phone}</div>
                        </div>
                        
                        <div class="info-block">
                            <h3>Client</h3>
                            <div><strong>${clientData.name}</strong></div>
                            <div>${clientData.address}</div>
                            <div>MF: ${clientData.taxNumber}</div>
                        </div>
                    </div>

                    <table class="items-table">
                        <thead>
                            <tr>
                                <th>Désignation</th>
                                <th>Qté</th>
                                <th>Unité</th>
                                <th>Prix Unitaire</th>
                                <th>Total HT</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${productList.map(item => `
                                <tr>
                                    <td>${item.product}</td>
                                    <td>${item.quantity}</td>
                                    <td>${item.unit}</td>
                                    <td>TND ${item.unitPrice.toFixed(2)}</td>
                                    <td>TND ${item.totalHT.toFixed(2)}</td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>

                    <div class="total-section">
                        <div></div>
                        <div>
                            <div class="total-line">
                                <span>Total HT:</span>
                                <span>TND ${sumHT.toFixed(2)}</span>
                            </div>
                            <div class="total-line">
                                <span>TVA (${clientData.tva}%):</span>
                                <span>TND ${tvaAmount.toFixed(2)}</span>
                            </div>
                            <div class="total-line">
                                <span>Timbre:</span>
                                <span>TND ${timbre.toFixed(2)}</span>
                            </div>
                            <div class="total-line grand-total">
                                <span>Total TTC:</span>
                                <span>TND ${totalTTC.toFixed(2)}</span>
                            </div>
                        </div>
                    </div>

                    <div class="payment-info">
                        <h3>Signature</h3>
                           <br/>
                        <br/>
                        <br/>
                      
                    </div>

                  
                </div>
            </body>
        </html>
    `);

    printWindow.document.close();
    printWindow.print();
};
  

    
  return (
    <Box sx={{ p: 3, backgroundColor: 'grey.100', minHeight: '100vh' }}>
      {showForm ? (
        <ClientForm
          clientData={clientData}
          handleChange={handleClientChange}
          handleSubmit={() => setShowForm(false)}
        />
      ) : (
        <Box ref={ref}>
          <InvoicePreview
            clientData={clientData}
            productList={productList}
            printInvoice={printInvoice}
            setOpenProductDialog={setOpenProductDialog}
            editClient={() => setShowForm(true)}
          />
        </Box>
      )}
      <ProductDialog
        open={openProductDialog}
        onClose={() => setOpenProductDialog(false)}
        product={product}
        handleProductChange={handleProductChange}
        addProduct={addProduct}
      />
    </Box>
  );
};

export default PdfTemplate;
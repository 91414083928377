import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Box,
  Typography,
  useTheme
} from '@mui/material';
import { CalendarToday, AttachMoney, Description } from '@mui/icons-material';

const ExcelTableAccompte = ({ workerData }) => {
  const theme = useTheme();
  const data = Array.isArray(workerData?.accompte) ? workerData.accompte : [];

  const formatDate = (date) => {
    if (!date) return "Date Inconnue";
    const parsedDate = new Date(date.seconds * 1000);
    return parsedDate.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
  };

  return (
    <TableContainer 
      component={Paper}
      sx={{
        borderRadius: 4,
        boxShadow: theme.shadows[5],
        overflow: 'hidden',
        mt: 3,
        width: '98%',
        border: `2px solid ${theme.palette.primary.main}` // Blue border
      }}
    >
      <Table aria-label="accompte table">
        <TableHead>
          <TableRow sx={{
            background: `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`
          }}>
            <TableCell sx={{ color: 'white', fontSize: 16, fontWeight: 600 }}>
              <Box display="flex" alignItems="center">
                <CalendarToday sx={{ mr: 1, fontSize: 20, color: 'white' }} />
                Date Acompte
              </Box>
            </TableCell>
            <TableCell sx={{ color: 'white', fontSize: 16, fontWeight: 600 }}>
              <Box display="flex" alignItems="center">
                <AttachMoney sx={{ mr: 1, fontSize: 20, color: 'white' }} />
                Montant Acompte
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((accompte, index) => (
              <TableRow
                key={index}
                hover
                sx={{
                  backgroundColor: index % 2 === 0 ? theme.palette.action.hover : "transparent",
                  '&:last-child td': { borderBottom: 0 }
                }}
              >
                <TableCell sx={{ py: 2 }}>
                  <Box display="flex" alignItems="center">
                    <Description sx={{ mr: 1, color: theme.palette.primary.main }} />
                    <Typography variant="body1" color="primary">
                      {formatDate(accompte.date)}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ py: 2 }}>
                  <Chip
                    label={`${accompte.amount || "Montant Inconnu"} TND`}
                    color="primary"
                    variant="filled"
                    sx={{
                      fontWeight: 600,
                      fontSize: 14,
                      px: 2,
                      backgroundColor: theme.palette.primary.main,
                      color: 'white',
                      '& .MuiChip-label': { pl: 1 }
                    }}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={2} sx={{ py: 4, textAlign: 'center' }}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Description sx={{ mr: 1, color: theme.palette.primary.light }} />
                  <Typography variant="body1" color="primary">
                    Aucune donnée d'acompte disponible
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExcelTableAccompte;

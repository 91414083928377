import React, { useState } from "react";
import { doc, setDoc, collection, getFirestore } from "firebase/firestore";
import { 
  Grid, 
  TextField, 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Chip,
  Typography,
  Box,
  Alert
} from "@mui/material";
import { DateRange, AttachMoney, Save } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import * as XLSX from 'xlsx';

import { FileDownload } from '@mui/icons-material';
const db = getFirestore();

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ExcelTablePartenaire = ({ partenairesData, encaissement }) => {
  const [searchDateDebut, setSearchDateDebut] = useState("");
  const [searchDateFin, setSearchDateFin] = useState("");
  const [montantRecu, setMontantRecu] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Group and filter encaissements
  const groupEncaissementsByDate = (encaissements) => 
    encaissements.reduce((acc, curr) => {
      const date = curr.date;
      if (!acc[date]) acc[date] = [];
      acc[date].push(curr);
      return acc;
    }, {});

  const filteredEncaissements = Object.entries(
    groupEncaissementsByDate(encaissement)
  ).filter(([date]) => {
    const dateObj = new Date(date);
    return (!searchDateDebut || dateObj >= new Date(searchDateDebut)) &&
           (!searchDateFin || dateObj <= new Date(searchDateFin));
  });

  // Calculate totals
  const calculateTotalNet = () => filteredEncaissements.reduce((total, [_, items]) => {
    items.forEach(item => {
      const partner = partenairesData.find(p => p.name.toLowerCase() === item.type.toLowerCase());
      if (partner) {
        const commission = (item.value * partner.commission) / 100;
        total += item.value - commission - (commission * 0.19);
      }
    });
    return total;
  }, 0);

  const totalNet = calculateTotalNet();
  const ecart = totalNet - montantRecu;

  // Save entry handler
  const handleSaveEntry = async () => {
    setLoading(true);
    setError(null);
    
    try {
      await setDoc(doc(collection(db, "MontantDateStatue")), {
        DateDebut: searchDateDebut,
        DateFin: searchDateFin,
        MontantRecu: montantRecu,
        TotalNet: totalNet,
        Ecart: ecart,
        PartenaireId: partenairesData.find(p => 
          p.name.toLowerCase() === filteredEncaissements[0]?.[1]?.[0]?.type.toLowerCase()
        )?.id,
      });
      window.location.reload();
    } catch (err) {
      setError("Error saving entry: " + err.message);
    } finally {
      setLoading(false);
    }
  };
  const exportToExcel = () => {
    // Prepare data for export (filtered encaissements)
    const tableData = filteredEncaissements.flatMap(([date, items]) => 
      items.map(item => ({
        Date: new Date(date).toLocaleDateString('fr-FR'),
        Partenaire: item.type,  // Assuming the 'type' is the partner's name
        "Chiffre d'affaire Brut": item.value,
        Commission: (item.value * partenairesData.find(p => p.name.toLowerCase() === item.type.toLowerCase())?.commission || 0) + '%',
        "Net a payer Theroique": item.value - (item.value * (partenairesData.find(p => p.name.toLowerCase() === item.type.toLowerCase())?.commission || 0) / 100),
      }))
    );
  
    // Create the Excel sheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Encaissements");
  
    // Export the data as an Excel file
    XLSX.writeFile(workbook, `Encaissements_${new Date().toLocaleDateString()}.xlsx`);
  };
  

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 4 }}>
          <Button 
            variant="contained" 
            color="primary" 
            startIcon={<FileDownload />}
            onClick={exportToExcel}
            sx={{ mb: 2 }}
          >
            Exporter en Excel
          </Button>
      {/* Header and Filters */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <DateRange fontSize="large" color="primary" />
          Partner Financial Overview
        </Typography>

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              type="date"
              label="Start Date"
              value={searchDateDebut}
              onChange={(e) => setSearchDateDebut(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              type="date"
              label="End Date"
              value={searchDateFin}
              onChange={(e) => setSearchDateFin(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', gap: 2 }}>
            <Button 
              variant="outlined" 
              onClick={() => {
                setSearchDateDebut("");
                setSearchDateFin("");
              }}
            >
              Clear Dates
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Data Table */}
      <TableContainer component={Paper} sx={{ borderRadius: 3, mb: 4 }}>
        <Table sx={{ minWidth: 650 }} aria-label="financial-table">
          <TableHead sx={{ bgcolor: 'primary.main' }}>
            <TableRow>
              {["Date", "Partner", "Gross Revenue", "Commission %", "Commission Amount", "VAT", "Net Payable"].map(
                (header) => (
                  <TableCell key={header} sx={{ color: 'white', fontWeight: 'bold' }}>
                    {header}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEncaissements.map(([date, items], index) => {
              const totalValue = items.reduce((sum, item) => sum + item.value, 0);
              const partner = partenairesData.find(p => 
                p.name.toLowerCase() === items[0]?.type.toLowerCase()
              );
              const commission = (totalValue * (partner?.commission || 0)) / 100;
              const vat = commission * 0.19;
              const net = totalValue - commission - vat;

              return (
                <StyledTableRow key={index}>
                  <TableCell>{date}</TableCell>
                  <TableCell>{items[0]?.type}</TableCell>
                  <TableCell align="center">{totalValue.toFixed(2)}</TableCell>
                  <TableCell align="center">{partner?.commission || 0}</TableCell>
                  <TableCell align="center">{commission.toFixed(2)}</TableCell>
                  <TableCell align="center">{vat.toFixed(2)}</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                    {net.toFixed(2)}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Summary Section */}
      <Box sx={{ 
        p: 3, 
        bgcolor: 'background.paper', 
        borderRadius: 3,
        boxShadow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}>
        <Typography variant="h6" gutterBottom>
          Financial Summary
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Received Amount"
              type="number"
              value={montantRecu}
              onChange={(e) => setMontantRecu(e.target.value)}
              InputProps={{
                startAdornment: <AttachMoney sx={{ color: 'action.active', mr: 1 }} />,
              }}
            />
          </Grid>
          
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="body1">Balance Difference:</Typography>
            <Chip
              label={`${ecart.toFixed(2)} TND`}

              color={ecart >= 0 ? 'success' : 'error'}
              variant="outlined"
              sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}
              
            /> 
          </Grid>
        </Grid>

        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

        <Button
          variant="contained"
          onClick={handleSaveEntry}
          disabled={!searchDateDebut || !searchDateFin || loading}
          startIcon={<Save />}
          sx={{ mt: 2, alignSelf: 'flex-start' }}
        >
          {loading ? 'Saving...' : 'Save Financial Record'}
        </Button>
      </Box>
    </Paper>
  );
};

export default ExcelTablePartenaire;
import React, { useState, useEffect } from 'react';
import jsonData from '../Components/TableExcel/cleaned_data.json';
import { 
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses,
    CircularProgress,
    Chip,
    Tooltip,
    useTheme,
    Box,
    styled
} from '@mui/material';
import { getFirestore, getDocs, collection } from 'firebase/firestore';
import {
    AttachMoney,
    Work,
    Person,
    MonetizationOn,
    AccountBalance,
    Warning,
    Info,
    GroupWork
} from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: 'bold',
        padding: theme.spacing(2),
        textAlign: 'center', // Center text in the header cell
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: theme.spacing(1.5),
        textAlign: 'center', // Center text in the body cell
    },
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
}));

const ExcelSearchScreen = ({ selectedWorkerId }) => {
    const theme = useTheme();
    const db = getFirestore();
    const [filteredRows, setFilteredRows] = useState([]);
    const [workers, setWorkers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const restaurantId = localStorage.getItem("restaurantId");

    useEffect(() => {
        const getAllWorkersByRestaurantId = async (restaurantId) => {
            try {
                const workersSnapshot = await getDocs(collection(db, 'workers'));
                let workersData = workersSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                if (selectedWorkerId) {
                    workersData = workersData.filter((worker) => worker.id === selectedWorkerId);
                }

                setWorkers(workersData);
            } catch (error) {
                console.error('Error fetching worker data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        getAllWorkersByRestaurantId(restaurantId);
    }, [selectedWorkerId, db, restaurantId]);

    const filterRowsForWorker = (worker) => {
        return jsonData.filter((row) => {
            const rowNetValue = Math.floor(Number(row.net));
            const inputNetValue = Math.floor(Number(worker.salary));
            const isNetMatch = rowNetValue === inputNetValue;
            const isSituationMatch = row.Situation === worker.statue;
            return isNetMatch && isSituationMatch;
        });
    };

    useEffect(() => {
        if (workers.length > 0) {
            const updatedFilteredRows = workers
                .filter((worker) => !selectedWorkerId || worker.id === selectedWorkerId || worker.restaurantId === restaurantId)
                .map((worker) => ({
                    worker,
                    rows: filterRowsForWorker(worker),
                }));
            setFilteredRows(updatedFilteredRows);
        }
    }, [workers, selectedWorkerId, restaurantId]);

    const formatNumber = (value) => 
        new Intl.NumberFormat('en-US', { 
            minimumFractionDigits: 3,
            maximumFractionDigits: 3 
        }).format(value);

    const getHeaderIcon = (header) => {
        const icons = {
            'Nom Salarié': <Person fontSize="small" />,
            'Situation': <Work fontSize="small" />,
            'Salaire Brut': <AttachMoney fontSize="small" />,
            'CNSS': <AccountBalance fontSize="small" />,
            'Salaire Imposable': <MonetizationOn fontSize="small" />,
            'IRPP': <Info fontSize="small" />,
            'CSS': <GroupWork fontSize="small" />,
            'Net': <AttachMoney fontSize="small" />,
            'TFP': <Warning fontSize="small" />,
            'FOPROLOS': <Warning fontSize="small" />,
            'Sécurité Sociale': <AccountBalance fontSize="small" />,
            'Accident de Travail': <Warning fontSize="small" />,
            'Cout Total': <AttachMoney fontSize="small" />
        };
        return icons[header] || <Info fontSize="small" />;
    };

    return (
        <Container maxWidth="xl" sx={{ my: 3 }}>
            <Typography variant="h5" gutterBottom sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                color: theme.palette.primary.dark,
                mb: 4,
                fontWeight: 600
            }}>
                <MonetizationOn fontSize="large" />
                Employee Financial Analytics
            </Typography>

            {isLoading ? (
                <Paper elevation={3} sx={{ 
                    p: 4, 
                    display: 'flex', 
                    justifyContent: 'center',
                    borderRadius: 3,
                    backgroundColor: theme.palette.background.paper
                }}>
                    <CircularProgress size={60} thickness={4} />
                </Paper>
            ) : (
                <Paper elevation={3} sx={{ borderRadius: 3, overflow: 'hidden' }}>
                    {filteredRows.length > 0 ? (
                        <TableContainer>
                            <Table stickyHeader aria-label="financial table">
                                <TableHead>
                                    <TableRow>
                                        {[
                                            'Nom Salarié',
                                            'Situation',
                                            'Salaire Brut',
                                            'CNSS',
                                            'Salaire Imposable',
                                            'IRPP',
                                            'CSS',
                                            'Net',
                                            'TFP',
                                            'FOPROLOS',
                                            'Sécurité Sociale',
                                            'Accident de Travail',
                                            'Cout Total'
                                        ].map((header) => (
                                            <StyledTableCell key={header}>
                                                <Tooltip title={header} arrow>
                                                    <Box sx={{ 
                                                        display: 'flex', 
                                                        alignItems: 'center', 
                                                        gap: 1 
                                                    }}>
                                                        {getHeaderIcon(header)}
                                                        {header}
                                                    </Box>
                                                </Tooltip>
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredRows
                                        .filter(workerData => workerData.worker.restaurantId === restaurantId)
                                        .map((workerData, index) => (
                                            <React.Fragment key={index}>
                                                {workerData.rows.length > 0 ? (
                                                    workerData.rows.map((row, rowIndex) => (
                                                        <StyledTableRow key={rowIndex} >
                                                            <StyledTableCell >
                                                                <Chip
                                                                    label={workerData.worker.name}
                                                                    color="primary"
                                                                    
                                                                    variant="outlined"
                                                                    sx={{ fontWeight: 500 , }}
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <Chip
                                                                    label={row.Situation}
                                                                    color={
                                                                        row.Situation === 'Marie' ? 
                                                                        'success' : 'default'
                                                                    }
                                                                    variant="outlined"
                                                                 
                                                                />
                                                            </StyledTableCell>
                                                            {[
                                                                'Salaire brut',
                                                                'cnss',
                                                                'Salaire Imposable',
                                                                'IRPP',
                                                                'CSS',
                                                                'net'
                                                            ].map((key) => (
                                                                <StyledTableCell key={key}>
                                                                    {formatNumber(row[key])}
                                                                </StyledTableCell>
                                                            ))}
                                                            <StyledTableCell>
                                                                {formatNumber(row['Salaire brut'] * 0.02)}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {formatNumber(row['Salaire brut'] * 0.01)}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {formatNumber(row['Salaire brut'] * 0.1657)}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {formatNumber(row['Salaire brut'] * 0.005)}
                                                            </StyledTableCell>
                                                            <StyledTableCell sx={{ fontWeight: 'bold' }}>
                                                                {formatNumber(
                                                                    row['Salaire brut'] * 0.1657 +
                                                                    row['Salaire brut'] * 0.01 +
                                                                    row['Salaire brut'] * 0.02 +
                                                                    row['Salaire brut'] * 0.005 +
                                                                    row['Salaire brut']
                                                                )}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell colSpan={13} align="center" sx={{ py: 4 }}>
                                                            <Box sx={{ 
                                                                display: 'flex', 
                                                                alignItems: 'center', 
                                                                gap: 2,
                                                                color: theme.palette.text.secondary
                                                            }}>
                                                                <Warning fontSize="large" />
                                                                <Typography variant="body1">
                                                                    No financial records found for {workerData.worker.name}
                                                                </Typography>
                                                            </Box>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </React.Fragment>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Box sx={{ 
                            p: 4, 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            gap: 2,
                            color: theme.palette.text.secondary
                        }}>
                            <GroupWork fontSize="large" />
                            <Typography variant="h6">
                                No employees found matching current criteria
                            </Typography>
                        </Box>
                    )}
                </Paper>
            )}
        </Container>
    );
};

export default ExcelSearchScreen;
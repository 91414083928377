import { getFirestore, doc, setDoc, getDoc, updateDoc, deleteDoc, collection, getDocs, query, where } from "firebase/firestore";
import Product from "./FoodCost";
const db = getFirestore();

class Ingredient {
  constructor(name, pricePerUnit, unit, restaurantId) {
    this.name = name;
    this.pricePerUnit = pricePerUnit;
    this.unit = unit; // e.g., "kg", "liter", "gram"
    this.restaurantId = restaurantId; // Restaurant ID to associate with the ingredient
    this.id = null; // Firestore ID
  }

  // Add a new ingredient
  async addIngredient() {
    try {
      const ingredientRef = doc(collection(db, "ingredients"));

      await setDoc(ingredientRef, {
        name: this.name,
        pricePerUnit: this.pricePerUnit,
        unit: this.unit,
        restaurantId: this.restaurantId, // Save the restaurantId
      });

      this.id = ingredientRef.id;
      console.log("Ingredient added successfully!");
    } catch (error) {
      console.error("Error adding ingredient:", error.message);
      throw new Error(error.message);
    }
  }

  // Get ingredient details
  static async getIngredient(ingredientId) {
    try {
      const ingredientRef = doc(db, "ingredients", ingredientId);
      const ingredientSnap = await getDoc(ingredientRef);

      if (!ingredientSnap.exists()) {
        throw new Error("Ingredient not found.");
      }

      return { id: ingredientSnap.id, ...ingredientSnap.data() };
    } catch (error) {
      console.error("Error retrieving ingredient:", error.message);
      throw new Error(error.message);
    }
  }

  // Update ingredient details
  static async updateIngredient(ingredientId, updates) {
    try {
      const ingredientRef = doc(db, "ingredients", ingredientId);
      const ingredientSnap = await getDoc(ingredientRef);

      if (!ingredientSnap.exists()) {
        throw new Error("Ingredient does not exist.");
      }

      // Update the ingredient
      await updateDoc(ingredientRef, updates);
      console.log("Ingredient updated successfully!");

      // After updating the ingredient, update all products using this ingredient
      await Product.updateProductsByIngredient(ingredientId);

    } catch (error) {
      console.error("Error updating ingredient:", error.message);
      throw new Error(error.message);
    }
  }

  // Delete an ingredient
  static async deleteIngredient(ingredientId) {
    try {
      const ingredientRef = doc(db, "ingredients", ingredientId);
      await deleteDoc(ingredientRef);
      console.log("Ingredient deleted successfully!");
    } catch (error) {
      console.error("Error deleting ingredient:", error.message);
      throw new Error(error.message);
    }
  }

  // Get all ingredients by restaurantId
  static async getAllIngredientsByRestaurant(restaurantId) {
    try {
      const q = query(collection(db, "ingredients"), where("restaurantId", "==", restaurantId));
      const querySnapshot = await getDocs(q);

      const ingredients = [];
      querySnapshot.forEach((doc) => {
        ingredients.push({ id: doc.id, ...doc.data() });
      });

      return ingredients;
    } catch (error) {
      console.error("Error retrieving ingredients by restaurantId:", error.message);
      throw new Error(error.message);
    }
  }
}

export default Ingredient;

import { Button, Grid, Container, Typography, Box, styled } from '@mui/material';
import DownloadIcon from '@mui/icons-material/DownloadForOffline';

const StyledContainer = styled(Container)({
  minHeight: '80vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
});

const PDFDownloadPage = () => {
  // Path should be relative to public folder
  const pdfLocation = '/assets/images/MENSUELLE__2023.pdf';

  // Handle download with proper error handling
  const handleDownload = async () => {
    try {
      const response = await fetch(pdfLocation);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'MENSUELLE_2023.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Download failed:', error);
      alert('Failed to download PDF. Please try again.');
    }
  };

  return (
    <StyledContainer maxWidth="md">
      <Box mb={4}>
        <Typography variant="h3" gutterBottom>
        Déclaration mensuel
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
        Téléchargez le rapport financier mensuel complet
        </Typography>
      </Box>

      <Grid container justifyContent="center">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<DownloadIcon />}
            onClick={handleDownload}
            sx={{
              px: 6,
              py: 2,
              borderRadius: '50px',
              fontSize: '1.1rem',
              fontWeight: 'bold',
              boxShadow: 3,
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: 6
              },
              transition: 'all 0.3s ease'
            }}
          >
           Télécharger le rapport
          </Button>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Typography variant="caption" color="textSecondary">
        Format de fichier : PDF | Taille du fichier : 1,1 MB
        </Typography>
      </Box>
    </StyledContainer>
  );
};

export default PDFDownloadPage;
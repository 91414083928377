import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from "../../Firebase/Config";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Collapse,
  useMediaQuery,
  Avatar,
  Typography,
  Box,
  styled
} from '@mui/material';
import {
  Home,
  Analytics,
  Lock,
  Group,
  Restaurant,
  Business,
  Receipt,
  Settings,
  Help,
  Logout,
  Menu,
  People, 
  Description,
  ExpandMore,
  ExpandLess
} from '@mui/icons-material';

const SidebarContainer = styled(Box)(({ theme }) => ({
  width: 280,
  background: '#FFFFFF',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  borderRight: '1px solid #F0F0F0',
  boxShadow: '0 8px 32px rgba(0,0,0,0.05)'
}));

const MenuItem = styled(ListItem)(({ theme, selected }) => ({
  borderRadius: 8,
  margin: theme.spacing(0.25, 1.5),
  padding: theme.spacing(1),
  transition: 'all 0.2s ease',
  backgroundColor: selected ? '#F5F7FF' : 'transparent',
  '&:hover': {
    backgroundColor: '#F5F7FF',
    transform: 'translateX(4px)'
  },
  '& .MuiListItemIcon-root': {
    minWidth: 36,
    color: selected ? '#3F51B5' : '#616161',
    fontSize: '1.2rem'
  }
}));

const menuItems = [
  { to: '/Home', icon: <Home />, label: 'Dashboard' },
  { to: '/Analytics', icon: <Analytics />, label: 'Analytics' },
  {
    icon: <Lock />,
    label: 'Vault',
    to: '/vault',
    children: [
      { to: '/Encaissement', label: 'Encaissement' },
      { to: '/Decaissement', label: 'Decaissement' }
    ]
  },
  
  { to: '/Stock', icon: <Receipt />, label: 'Inventory' },
  { to: '/rh', icon: <Group />, label: 'HR Management' },
  { to: '/foodcost', icon: <Restaurant />, label: 'Food Cost' },
  { to: '/fournisseurs', icon: <Business />, label: 'Suppliers' },
  { to: '/Partenaire', icon: <People />, label: 'Partenaires' },
  { to: '/Facture', icon: <Receipt />, label: 'Facture' },
  { to: '/Formulaire', icon: <Description />, label: 'Formulaire'}
];

const settingsItems = [
  { to: '/profile', icon: <Settings />, label: 'Settings' },
  { to: '/help-center', icon: <Help />, label: 'Support' }
];

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isOpen, setIsOpen] = useState(!isMobile);
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.clear();
      sessionStorage.clear();
      navigate('/');
    } catch (error) {
      console.error("Logout failed: ", error.message);
    }
  };

  const handleVaultClick = () => {
    setOpenSubmenu(!openSubmenu);
  };

  const isVaultActive = location.pathname.startsWith('/vault');

  return (
    <>
      <Drawer
        variant={isMobile ? 'temporary' : 'persistent'}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          width: isOpen ? 280 : 0,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            overflowX: 'hidden'
          },
        }}
      >
        <SidebarContainer>
          {/* Compact Header */}
          <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Avatar 
              src={`${process.env.PUBLIC_URL}/assets/images/LogoOptimizi.png`}
              sx={{ width: 36, height: 36 }}
            />
            <Typography variant="h6" color="#1A1A1A" fontSize="1.1rem">
              Optimizi
            </Typography>
          </Box>

          {/* Compact User Profile */}
          <Box sx={{ px: 2, mb: 1 }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1.5,
              p: 1.5,
              borderRadius: 2,
              backgroundColor: '#F8F9FF'
            }}>
              <Avatar 
                src={`${process.env.PUBLIC_URL}/assets/images/ProfilePic.jpg`}
                sx={{ width: 36, height: 36 }}
              />
              <Box>
                <Typography variant="subtitle2" fontWeight={600}>
                  {user?.prenom} {user?.nom}
                </Typography>
                <Typography variant="caption" color="#666">
                  {user?.role}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Optimized Main Menu */}
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
  <List sx={{ flex: 1, overflow: 'hidden', '&:hover': { overflow: 'auto' } }}>
    {menuItems.map((item) => (
      item.children ? (
        <React.Fragment key={item.label}>
          {/* Wrap the Vault MenuItem in Link */}
          <MenuItem
            button
            component={Link}
            to={item.to} // Ensure it navigates to '/vault'
            onClick={handleVaultClick}
            selected={isVaultActive}
          >
            <ListItemIcon sx={{ fontSize: '1.1rem' }}>{item.icon}</ListItemIcon>
            <ListItemText 
              primary={item.label} 
              primaryTypographyProps={{
                fontSize: '0.9rem',
                fontWeight: 500,
                color: isVaultActive ? '#3F51B5' : '#1A1A1A'
              }}
            />
            {openSubmenu ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.children.map((child) => (
                <MenuItem
                  key={child.to}
                  button
                  component={Link}
                  to={child.to}
                  selected={location.pathname === child.to}
                  sx={{ pl: 6 }}
                >
                  <ListItemText 
                    primary={child.label} 
                    primaryTypographyProps={{
                      fontSize: '0.9rem',
                      fontWeight: 500,
                      color: location.pathname === child.to ? '#3F51B5' : '#1A1A1A'
                    }}
                  />
                </MenuItem>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ) : (
        <MenuItem
          key={item.to}
          button
          component={Link}
          to={item.to}
          selected={location.pathname === item.to}
        >
          <ListItemIcon sx={{ fontSize: '1.1rem' }}>{item.icon}</ListItemIcon>
          <ListItemText 
            primary={item.label} 
            primaryTypographyProps={{
              fontSize: '0.9rem',
              fontWeight: 500,
              color: location.pathname === item.to ? '#3F51B5' : '#1A1A1A'
            }}
          />
        </MenuItem>
      )
    ))}
  </List>



            {/* Fixed Footer */}
            <Box sx={{ p: 1, borderTop: '1px solid #F0F0F0', mt: 'auto' }}>
              <List dense>
                {settingsItems.map((item) => (
                  <MenuItem
                    key={item.to}
                    button
                    component={Link}
                    to={item.to}
                    sx={{ py: 0.5 }}
                  >
                    <ListItemIcon sx={{ fontSize: '1rem' }}>{item.icon}</ListItemIcon>
                    <ListItemText 
                      primary={item.label} 
                      primaryTypographyProps={{ fontSize: '0.85rem' }}
                    />
                  </MenuItem>
                ))}
                <MenuItem button onClick={handleLogout} sx={{ py: 0.5 }}>
                  <ListItemIcon sx={{ fontSize: '1rem' }}><Logout /></ListItemIcon>
                  <ListItemText 
                    primary="Logout" 
                    primaryTypographyProps={{ fontSize: '0.85rem' }}
                  />
                </MenuItem>
              </List>
            </Box>
          </Box>
        </SidebarContainer>
      </Drawer>

      {/* Enhanced Toggle Button */}
      <IconButton
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          position: 'fixed',
          top: 12,
          left: 12,
          zIndex: 1200,
          backgroundColor: '#FFF',
          boxShadow: 2,
          '&:hover': { 
            backgroundColor: '#F5F5F5',
            transform: 'scale(1.1)'
          },
          transition: 'all 0.2s ease'
        }}
      >
        <Menu fontSize="small" />
      </IconButton>
    </>
  );
}

export default Sidebar;
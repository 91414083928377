import React, { useState, useEffect } from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Grid, Card, Button, Typography, Box, Avatar, CircularProgress, useTheme } from '@mui/material';
import { 
  AttachMoney, Receipt, Percent, Description, Warning, 
  People, Assessment, TrendingUp, TrendingDown 
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { auth } from "../Firebase/Config";
import AddRestaurantFormPopup from '../Components/PopUpAddResto';
import { styled } from '@mui/system';
import RapportX from '../Services/RapportX';

// Custom styled components

const GradientCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '16px',
  background: `linear-gradient(145deg, ${theme.palette.background.paper}, #f8f9ff)`,
  boxShadow: '0 8px 32px rgba(0,0,0,0.05)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 48px rgba(0,0,0,0.1)'
  }
}));

const StatIconWrapper = styled('div')(({ theme, color }) => ({
  width: 48,
  height: 48,
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(135deg, ${color}30, ${color}10)`,
  color: color,
  marginRight: theme.spacing(2)
}));

// Chart color scheme
const chartColors = {
  primary: '#6366f1',
  secondary: '#10b981'
};

// Sample data
const data = [
  { name: 'Jan', sales: 4000, profit: 2400 },
  { name: 'Feb', sales: 3000, profit: 1398 },
  { name: 'Mar', sales: 2000, profit: 9800 },
  { name: 'Apr', sales: 2780, profit: 3908 },
  { name: 'May', sales: 1890, profit: 4800 },
  { name: 'Jun', sales: 2390, profit: 3800 },
  { name: 'Jul', sales: 3490, profit: 4300 },
];

const statsData = [
  { title: 'Total Revenue', value: '20,000 TND', icon: <AttachMoney />, color: '#3b82f6' },
  { title: 'Total Purchases', value: '1,600 TND', icon: <Receipt />, color: '#10b981' },
  { title: 'Deductible VAT', value: '2,000 TND', icon: <Percent />, color: '#8b5cf6' },
  { title: 'Collected VAT', value: '150 TND', icon: <Description />, color: '#f59e0b' },
  { title: 'Unpaid Invoices', value: '300 TND', icon: <Warning />, color: '#ef4444' },
  { title: 'Payroll Costs', value: '2,000 TND', icon: <People />, color: '#3b82f6' },
  { title: 'Food Cost', value: '1,020 TND', icon: <Assessment />, color: '#10b981' },
];

function Dashboard() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [restaurants, setRestaurants] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const [totalRapportX, setTotalRapportX] = useState({});
  const restaurantId = localStorage.getItem("restaurantId");
  // Sample calculation for CATTC (for example, could be a sum of purchases + VAT)
  const calculateCATTC = 1500 + 1600 + 2000; // Example calculation for Total CATTC

  const fetchRestaurants = async () => {
    try {
      setLoading(true);
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error('User not logged in');
      }

      const restaurantsRef = collection(db, 'restaurants');
      const q = query(restaurantsRef, where('ownerId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const restaurantData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setRestaurants(restaurantData);

      // Save the first restaurant ID (or any specific restaurant ID) in localStorage
      if (restaurantData.length > 0) {
        localStorage.setItem('restaurantId', restaurantData[0].id);
        localStorage.setItem('restaurantName', restaurantData[0].name);
      }
      
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRestaurants(); // Already fetching restaurants
    fetchData(); // Fetch RapportX data when the page loads
  }, []);  // Empty dependency array ensures this runs on initial load
  
  const fetchData = async () => {
    setLoading(true); // Set loading state before fetching data
    try {
      const total = await RapportX.getTotalRapportXPerMonth(restaurantId); // Fetch the total for the current month
      setTotalRapportX(total); // Set the total in the state
      setLoading(false); // Stop loading after data is fetched
    } catch (err) {
      console.error(err);
      setError("Failed to fetch data"); // Handle errors
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
     
      if (user.isVerifed === false) {
        navigate('/addResto');
      }
    });
    return unsubscribe;
  }, []);

  // Logout function
  const handleLogout = async () => {
    try {
      // Sign out from Firebase authentication
      await signOut(auth);
      
      // Clear user and token data from local and session storage
      localStorage.removeItem('user');
      localStorage.removeItem('userToken');
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('userToken');
  
      // Redirect to the login page
      navigate('/');  
    } catch (error) {
      console.error("Logout failed: ", error.message);
    }
  };
  
  
  return (
    <Box sx={{ p: 4, background: theme.palette.background.default }}>
      {/* Header Section */}
      <Box sx={{ 
        mb: 6, 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center' 
      }}>
        <div>
          <Typography variant="h4" fontWeight="700" gutterBottom>
            Welcome back, {user?.prenom}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Here's your business overview
          </Typography>
        </div>
        <AddRestaurantFormPopup />
      </Box>



      {/* Statistics Grid */}
      <Grid container spacing={3} sx={{ mb: 6 }}>
        {statsData.map((stat, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <GradientCard>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StatIconWrapper color={stat.color}>
                  {React.cloneElement(stat.icon, { fontSize: 'large' })}
                </StatIconWrapper>
                <div>
                  <Typography variant="body2" color="text.secondary">
                    {stat.title}
                  </Typography>
                  <Typography variant="h5" fontWeight="600">
                    {stat.value}
                  </Typography>
                </div>
              </Box>
            </GradientCard>
          </Grid>
        ))}
      </Grid>
   




      {/* Charts Section */}
      <Grid container spacing={4} sx={{ mb: 6 }}>
        <Grid item xs={12} md={6}>
          <GradientCard>
            <Typography variant="h6" gutterBottom sx={{ 
              display: 'flex', 
              alignItems: 'center',
              color: chartColors.primary
            }}>
              <TrendingUp sx={{ mr: 1 }} /> Sales Overview
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="name" tick={{ fill: theme.palette.text.secondary }} />
                <YAxis tick={{ fill: theme.palette.text.secondary }} />
                <Tooltip 
                  contentStyle={{
                    background: theme.palette.background.paper,
                    border: 'none',
                    borderRadius: '8px',
                    boxShadow: theme.shadows[3]
                  }}
                />
                <Bar 
                  dataKey="sales" 
                  fill={chartColors.primary}
                  radius={[4, 4, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </GradientCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <GradientCard>
            <Typography variant="h6" gutterBottom sx={{ 
              display: 'flex', 
              alignItems: 'center',
              color: chartColors.secondary
            }}>
              <TrendingDown sx={{ mr: 1 }} /> Profit Trend
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="name" tick={{ fill: theme.palette.text.secondary }} />
                <YAxis tick={{ fill: theme.palette.text.secondary }} />
                <Tooltip 
                  contentStyle={{
                    background: theme.palette.background.paper,
                    border: 'none',
                    borderRadius: '8px',
                    boxShadow: theme.shadows[3]
                  }}
                />
                <Line 
                  type="monotone" 
                  dataKey="profit" 
                  stroke={chartColors.secondary}
                  strokeWidth={2}
                  dot={{ fill: chartColors.secondary }}
                />
              </LineChart>
            </ResponsiveContainer>
          </GradientCard>
        </Grid>
      </Grid>

      {/* Restaurants Section */}
      <Typography variant="h5" fontWeight="600" gutterBottom>
        Your Restaurants
      </Typography>
      <Grid container spacing={3} sx={{ mb: 6 }}>
        {restaurants.map((restaurant) => (
          <Grid item xs={12} sm={6} md={4} key={restaurant.id}>
            <GradientCard sx={{
              backgroundImage: 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5))',
              color: 'white',
              minHeight: 200,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              position: 'relative',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background: `url(${restaurant.image || '/default-restaurant.jpg'})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '16px',
                zIndex: -1
              }
            }}>
              <Typography variant="h6" fontWeight="600">
                {restaurant.name}
              </Typography>
              <Typography variant="body2">
                {restaurant.address || 'No address available'}
              </Typography>
              <Typography variant="body2">
                {restaurant.phone || 'No phone available'}
              </Typography>
            </GradientCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Dashboard;
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import RH from "../Services/RH";
import ExcelSearchScreen from "./RHEXCEL";
import { Timestamp } from "firebase/firestore";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
  Divider,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Avatar,
  Chip,
  Paper,
  useTheme,

  useMediaQuery,
  
} from "@mui/material";
import {
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  AccountCircle as AccountCircleIcon,
  AttachMoney as AttachMoneyIcon,
  Work as WorkIcon,
  Person as PersonIcon,
  Today as TodayIcon
} from "@mui/icons-material";
import ExcelTableAccompte from "../Components/TableExcel/ExcelTableAccompte";

const WorkerDetails = () => {
  const { workerId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [worker, setWorker] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [accompteAmount, setAccompteAmount] = useState("");
  const [accompteDate, setAccompteDate] = useState("");
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editedWorker, setEditedWorker] = useState({});
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    const fetchWorkerDetails = async () => {
      try {
        const workerData = await RH.getWorker(workerId);
        setWorker(workerData);
      } catch (error) {
        console.error("Error fetching worker details:", error.message);
      }
    };
    fetchWorkerDetails();
  }, [workerId]);

  const handleSaveWorkerDetails = async () => {
    try {
      await RH.editWorker(workerId, editedWorker);
      alert("Worker details updated successfully!");
      const updatedWorker = await RH.getWorker(workerId);
      setWorker(updatedWorker);
      handleEditDialogClose();
    } catch (error) {
      console.error("Error updating worker details:", error.message);
      alert("Failed to update worker details. Please try again.");
    }
  };

  const formatDate = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      const date = timestamp.toDate();
      return new Intl.DateTimeFormat("fr-FR", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      }).format(date);
    }
    return "Invalid Date";
  };
  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
  };
  
  const handleEditDialogOpen = () => {
    setEditedWorker({ ...worker });
    setIsEditDialogOpen(true);
  };
  
  const handleDialogOpen = () => setIsDialogOpen(true);
  
  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setAccompteAmount("");
    setAccompteDate("");
  };
  
  const handleDeleteAccompte = async (accompteId) => {
    if (!window.confirm("Êtes-vous sûr de vouloir supprimer cet acompte?")) {
      return;
    }
    try {
      await RH.deleteAccompte(workerId, accompteId);
      const updatedWorker = await RH.getWorker(workerId);
      setWorker(updatedWorker);
    } catch (error) {
      console.error("Error deleting accompte:", error.message);
      alert("Échec de la suppression de l'acompte. Veuillez réessayer.");
    }
  };
  
  const handleAddAccompte = async () => {
    if (!accompteAmount || isNaN(accompteAmount) || parseFloat(accompteAmount) <= 0) {
      alert("Veuillez entrer un montant valide");
      return;
    }
  
    if (!accompteDate) {
      alert("Veuillez sélectionner une date valide");
      return;
    }
  
    try {
      const formattedDate = new Date(accompteDate);
      await RH.addAccompte(workerId, parseFloat(accompteAmount), formattedDate);
      const updatedWorker = await RH.getWorker(workerId);
      setWorker(updatedWorker);
      handleDialogClose();
    } catch (error) {
      console.error("Error adding accompte:", error.message);
      alert("Échec de l'ajout de l'acompte. Veuillez réessayer.");
    }
  };
  
  const handleDeleteWorker = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer définitivement cet employé?")) {
      try {
        await RH.deleteWorker(workerId);
        navigate("/workers");
      } catch (error) {
        console.error("Error deleting worker:", error.message);
        alert("Échec de la suppression de l'employé. Veuillez réessayer.");
      }
    }
  };

  const currentDate = new Date();
const currentMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();

const totalAccomptes = worker?.accompte
  ?.filter(acc => {
    if (!acc.date || !acc.date.seconds) return false; // Ensure date exists
    const accDate = new Date(acc.date.seconds * 1000);
    return accDate.getMonth() === currentMonth && accDate.getFullYear() === currentYear;
  })
  .reduce((sum, acc) => sum + (acc.amount || 0), 0) || 0;


  if (!worker) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Typography variant="h5" color="textSecondary">
          Chargement des détails de l'employé...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{  overflowX: 'hidden', // Prevents horizontal scrolling
      width: '100%',       // Ensure it takes full width
      maxWidth: '100vw' }} >
      <ExcelSearchScreen worker={worker} selectedWorkerId={worker.id} />

      {/* Worker Profile Card */}
      <Card sx={{ mb: 4, boxShadow: 3, borderRadius: 4 ,width: '98%' }}>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={9}>
              <Stack direction="row" spacing={3} alignItems="center">
                <Avatar sx={{
                  width: 100,
                  height: 100,
                  bgcolor: theme.palette.primary.main,
                  fontSize: '2rem'
                }}>
                  {worker.name[0]}{worker.prenom[0]}
                </Avatar>

                <Stack spacing={1}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography variant="h3" fontWeight="700">
                      {worker.name} {worker.prenom}
                    </Typography>
                    <Chip
                      label={worker.statue}
                      color="secondary"
                      variant="outlined"
                      sx={{ fontSize: '0.75rem' }}
                    />
                  </Stack>

                  <Stack direction="row" spacing={4}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <WorkIcon color="action" />
                      <Typography variant="body1">{worker.role}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <AttachMoneyIcon color="action" />
                      <Typography variant="body1" sx={{ fontWeight:"bold"}}>
                        {(worker.salary).toFixed(3) } TND/mois
                      </Typography>
                      
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                <Tooltip title="Modifier les informations">
                  <IconButton
                    color="primary"
                    onClick={handleEditDialogOpen}
                    sx={{ border: `1px solid ${theme.palette.primary.main}` }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer employé">
                  <IconButton
                    color="error"
                    onClick={() => setIsDeleteDialogOpen(true)}
                    sx={{ border: `1px solid ${theme.palette.error.main}` }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>

          <Divider sx={{ my: 3 }} />

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper elevation={0} sx={{ p: 3, bgcolor: theme.palette.grey[100], borderRadius: 3 }}>
                <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                  <PersonIcon fontSize="small" />
                  Information Personnelle
                </Typography>
                <Stack spacing={1.5}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      CIN:
                    </Typography>
                    <Typography variant="body2" sx={{fontWeight:"bold",fontSize:"22px"}}>{worker.cin}</Typography>
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={0} sx={{ p: 3, bgcolor: theme.palette.grey[100], borderRadius: 3 }}>
                <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                  <AttachMoneyIcon fontSize="bold" sx={{color:"red"}} />
                  Reste a Payer
                </Typography>
                <Stack spacing={1.5}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Montant:
                    </Typography>
                    <Typography variant="body2" sx={{fontWeight:"bold",fontSize:"22px",color:"red"}}>{(worker.salary - parseFloat(totalAccomptes)).toFixed(3)} TND</Typography>
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Paper elevation={0} sx={{ p: 3, bgcolor: theme.palette.grey[100], borderRadius: 3 }}>
                <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                  <AttachMoneyIcon fontSize="small" />
                  Acomptes
                </Typography>
                <Stack spacing={1.5}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="textSecondary">
                      Total versé:
                    </Typography>
                    <Typography variant="body2" sx={{fontWeight:"bold",fontSize:"22px",color:"blue"} }>
                      {totalAccomptes.toFixed(3)} TND
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Accomptes Section */}
      <Card sx={{ mb: 4, boxShadow: 3, borderRadius: 4 ,width: '98%'}}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TodayIcon fontSize="medium" />
              Historique des Acomptes
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleDialogOpen}
              sx={{ borderRadius: 50 }}
            >
              Nouvel Acompte
            </Button>
          </Stack>

          {worker.accompte?.length > 0 ? (
            <List sx={{ maxHeight: 400, overflow: 'auto',width: '98%' }}>
              {worker.accompte.map((accompte, index) => (
                <Paper key={index} elevation={1} sx={{ mb: 1.5, borderRadius: 2 }}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography variant="body1" fontWeight="medium">
                          {accompte.amount} TND
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" color="textSecondary">
                          Le {formatDate(accompte.date)}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        color="error"
                        onClick={() => handleDeleteAccompte(accompte.id)}
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Paper>
              ))}
            </List>
          ) : (
            <Paper sx={{ p: 4, textAlign: 'center', bgcolor: theme.palette.grey[50] }}>
              <Typography variant="body1" color="textSecondary">
                Aucun acompte enregistré
              </Typography>
            </Paper>
          )}
        </CardContent>
      </Card>

      {/* Add Accompte Dialog */}
      <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="xs">
        <DialogTitle sx={{ bgcolor: theme.palette.primary.main, color: 'white' }}>
          Nouvel Acompte
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Stack spacing={3}>
            <TextField
              autoFocus
              label="Montant"
              type="number"
              fullWidth
              variant="outlined"
              InputProps={{ endAdornment: 'TND' }}
              value={accompteAmount}
              onChange={(e) => setAccompteAmount(e.target.value)}
            />
            <TextField
              label="Date"
              type="date"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={accompteDate}
              onChange={(e) => setAccompteDate(e.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={handleDialogClose} sx={{ color: 'text.secondary' }}>
            Annuler
          </Button>
          <Button
            onClick={handleAddAccompte}
            variant="contained"
            disableElevation
            sx={{ borderRadius: 2 }}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Worker Dialog */}
      <Dialog open={isEditDialogOpen} onClose={handleEditDialogClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ bgcolor: theme.palette.primary.main, color: 'white' }}>
          Modifier Employé
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nom"
                fullWidth
                variant="outlined"
                value={editedWorker.name || ""}
                onChange={(e) => setEditedWorker({ ...editedWorker, name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Prénom"
                fullWidth
                variant="outlined"
                value={editedWorker.prenom || ""}
                onChange={(e) => setEditedWorker({ ...editedWorker, prenom: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Rôle</InputLabel>
                <Select
                  label="Rôle"
                  value={editedWorker.role || ""}
                  onChange={(e) => setEditedWorker({ ...editedWorker, role: e.target.value })}
                >
                  {[
                    "Gérant",
                    "Chef de cuisine",
                    "Cuisinier",
                    "Aide-cuisinier",
                    "Serveur/Serveuse",
                    "Barman",
                    "Barmaid",
                    "Plongeur",
                    "Hôte",
                    "Hôtesse d'accueil",
                    "Responsable de salle"
                  ].map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Statut</InputLabel>
                <Select
                  label="Statut"
                  value={editedWorker.statue}
                  onChange={(e) => setEditedWorker({ ...editedWorker, statue: e.target.value })}
                >
                  {["Celibataire", "Marie", "M+1", "M+2", "M+3", "M+4"].map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Salaire (TND)"
                type="number"
                fullWidth
                variant="outlined"
                value={editedWorker.salary || ""}
                onChange={(e) => setEditedWorker({ ...editedWorker, salary: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="CIN"
                fullWidth
                variant="outlined"
                value={editedWorker.cin || ""}
                onChange={(e) => setEditedWorker({ ...editedWorker, cin: e.target.value })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={handleEditDialogClose} sx={{ color: 'text.secondary' }}>
            Annuler
          </Button>
          <Button
            onClick={handleSaveWorkerDetails}
            variant="contained"
            disableElevation
            sx={{ borderRadius: 2 }}
          >
            Sauvegarder
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          <Typography>
            Êtes-vous sûr de vouloir supprimer définitivement cet employé ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="inherit">
            Annuler
          </Button>
          <Button
            onClick={handleDeleteWorker}
            variant="contained"
            color="error"
            disableElevation
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>

      <ExcelTableAccompte workerData={worker} />
    </Container>
  );
};

export default WorkerDetails;
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  alpha,
  IconButton,
  Box
} from '@mui/material';
import { lightBlue, cyan } from '@mui/material/colors';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

const ExcelTable4 = ({ data, title }) => {
  const types = data ? [...new Set(data.map(row => row.type))] : [];
  const [currentPage, setCurrentPage] = React.useState(0);

  const { monthsData, initialPage } = React.useMemo(() => {
    if (!data) return { monthsData: [], initialPage: 0 };

    const groupedByMonth = data.reduce((acc, row) => {
      const dateObj = new Date(row.date);
      const monthKey = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}`;
      const dateKey = row.date;

      if (!acc[monthKey]) {
        acc[monthKey] = {
          monthKey,
          dates: {},
          total: 0
        };
      }

      if (!acc[monthKey].dates[dateKey]) {
        acc[monthKey].dates[dateKey] = {
          date: row.date,
          values: {},
          total: 0
        };
      }

      const type = row.type;
      acc[monthKey].dates[dateKey].values[type] = (acc[monthKey].dates[dateKey].values[type] || 0) + row.value;
      acc[monthKey].dates[dateKey].total += row.value;
      acc[monthKey].total += row.value;

      return acc;
    }, {});

    const monthsData = Object.values(groupedByMonth)
      .sort((a, b) => new Date(b.monthKey) - new Date(a.monthKey))
      .map(month => ({
        ...month,
        dates: Object.values(month.dates).sort((a, b) => new Date(b.date) - new Date(a.date))
      }));

    const currentDate = new Date();
    const currentMonthKey = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
    const initialPage = monthsData.findIndex(m => m.monthKey === currentMonthKey);

    return { 
      monthsData, 
      initialPage: initialPage !== -1 ? initialPage : 0 
    };
  }, [data]);

  React.useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  const formatNumber = num => 
    num?.toFixed?.(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '0.00';

  const formatMonth = (monthKey) => {
    if (!monthKey) return '';
    const [year, month] = monthKey.split('-');
    const date = new Date(year, month - 1);
    return date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
  };

  const handlePreviousPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 0));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, monthsData.length - 1));
  };

  return (
    <TableContainer 
      component={Paper} 
      sx={{ 
        overflowX: 'auto',
        borderRadius: 3,
        boxShadow: '0px 15px 35px rgba(50,50,93,0.1), 0px 5px 15px rgba(0,0,0,0.07)',
        background: 'linear-gradient(to bottom right, #f8f9fd, #ffffff)',
        maxWidth: '100%',
        mx: 'auto'
      }}
    >
      <Table 
        size="small" 
        sx={{ 
          width: '100%',
          minWidth: 'max-content', // Ensure table can shrink below content width
          '& .MuiTableCell-root': {
            transition: 'all 0.2s ease-in-out',
            whiteSpace: 'nowrap' // Prevent text wrapping
          }
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell 
              align="left" 
              rowSpan={2}
              sx={{
                fontWeight: 800,
                width: '120px', // Fixed width for date column
                verticalAlign: 'middle',
                borderRight: '1px solid rgba(224, 224, 224, 0.3)',
                background: lightBlue[600],
                color: 'common.white',
                fontSize: '0.95rem',
                letterSpacing: '0.5px',
                py: 2,
                borderBottom: 'none',
                position: 'sticky',
                left: 0,
                zIndex: 1
              }}
            >
              Date
            </TableCell>
            <TableCell 
              colSpan={types.length + 1} 
              align="center" 
              sx={{ 
                fontWeight: 800,
                background: lightBlue[600],
                color: 'common.white',
                fontSize: '1.1rem',
                letterSpacing: '0.8px',
                py: 2.5,
                borderBottom: 'none',
                position: 'sticky',
                left: 120, // Match date column width
                zIndex: 1
              }}
            >
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                gap: 2,
                px: 2
              }}>
                <IconButton 
                  onClick={handlePreviousPage} 
                  disabled={currentPage === 0}
                  sx={{ 
                    color: 'common.white',
                    '&:disabled': { opacity: 0.5 }
                  }}
                >
                  <ChevronLeft fontSize="medium" />
                </IconButton>
                <Typography variant="h6" sx={{ 
                  fontWeight: 700, 
                  minWidth: 200,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                  {formatMonth(monthsData[currentPage]?.monthKey)}
                </Typography>
                <IconButton 
                  onClick={handleNextPage} 
                  disabled={currentPage === monthsData.length - 1}
                  sx={{ 
                    color: 'common.white',
                    '&:disabled': { opacity: 0.5 }
                  }}
                >
                  <ChevronRight fontSize="medium" />
                </IconButton>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow sx={{ bgcolor: alpha(lightBlue[50], 0.4) }}>
            {types.map((type, index) => (
              <TableCell 
                key={index} 
                align="center" 
                sx={{ 
                  fontWeight: 600,
                  width: '150px', // Fixed width for type columns
                  borderRight: '1px solid rgba(224, 224, 224, 0.3)',
                  color: 'text.secondary',
                  py: 1.5,
                  fontSize: '0.9rem'
                }}
              >
                {type || 'N/A'}
              </TableCell>
            ))}
            <TableCell 
              align="center" 
              sx={{ 
                fontWeight: 700,
                width: '120px', // Fixed width for total column
                bgcolor: alpha(cyan[100], 0.3),
                color: cyan[800],
                fontSize: '0.95rem',
                backdropFilter: 'blur(3px)'
              }}
            >
              Total
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {monthsData.length > 0 ? (
            <>
              {monthsData[currentPage]?.dates.map((dateRow, index) => (
                <TableRow 
                  key={index}
                  hover
                  sx={{ 
                    '&:nth-of-type(even)': { bgcolor: alpha(lightBlue[50], 0.1) },
                    '&:hover': {
                      '& .MuiTableCell-root': {
                        bgcolor: alpha(lightBlue[50], 0.4)
                      }
                    }
                  }}
                >
                  <TableCell 
                    align="left" 
                    sx={{ 
                      fontWeight: 500,
                      width: '120px',
                      borderRight: '1px solid rgba(224, 224, 224, 0.3)',
                      color: 'text.secondary',
                      py: 1.2,
                      position: 'sticky',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: 'inherit'
                    }}
                  >
                    {dateRow.date || 'N/A'}
                  </TableCell>
                  {types.map((type, i) => (
                    <TableCell 
                      key={i} 
                      align="right"
                      sx={{
                        width: '150px',
                        borderRight: i === types.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 0.3)',
                        fontFamily: 'Space Mono, monospace',
                        color: 'text.primary',
                        py: 1.2,
                        fontSize: '0.95rem'
                      }}
                    >
                      {formatNumber(dateRow.values[type] || 0)}
                    </TableCell>
                  ))}
                  <TableCell 
                    align="right" 
                    sx={{ 
                      fontWeight: 700,
                      width: '120px',
                      bgcolor: alpha(cyan[100], 0.2),
                      color: cyan[900],
                      fontFamily: 'Space Mono, monospace',
                      py: 1.2,
                      fontSize: '0.95rem',
                      backdropFilter: 'blur(2px)'
                    }}
                  >
                    {formatNumber(dateRow.total)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow sx={{ bgcolor: alpha(cyan[100], 0.3) }}>
                <TableCell 
                  colSpan={types.length + 2} 
                  align="right" 
                  sx={{ 
                    fontWeight: 700,
                    color: cyan[900],
                    py: 1.2,
                    fontSize: '0.95rem',
                    backdropFilter: 'blur(2px)'
                  }}
                >
                  Monthly Total: {formatNumber(monthsData[currentPage]?.total || 0)}
                </TableCell>
              </TableRow>
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={types.length + 2} align="center" sx={{ py: 4 }}>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: 'text.secondary',
                    fontStyle: 'italic',
                    letterSpacing: '0.5px'
                  }}
                >
                  📊 No financial data available
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExcelTable4;
import React, { useState, useEffect } from "react";
import { 
  Button, TextField, Grid, Typography, Box, Card, CardContent, 
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton,
  Chip, Avatar, FormControl, InputLabel, Select, MenuItem, Slide,
  Collapse, Divider, Fab, Tooltip, Badge
} from "@mui/material";
import { 
  Add, Delete, Close, Article, Visibility, 
  ImportExport, ArrowBack, Paid, UnfoldMore 
} from "@mui/icons-material";
import { motion } from "framer-motion";
import Fournisseur from '../Services/Fournisseur';
import Facture from '../Services/FactureFournisseur';
import ExcelTableFournisseur from "../Components/TableExcel/ExcelTableFrounisseur";
import FactureComponent from "../Components/FactureComponent";
import { keyframes } from "@emotion/react";

const MotionCard = motion(Card);
const glowing = keyframes`
  0% {
    box-shadow: 0 0 5px #7b1fa2, 0 0 10px #4a148c;
  }
  50% {
    box-shadow: 0 0 10px #7b1fa2, 0 0 20px #4a148c;
  }
  100% {
    box-shadow: 0 0 5px #7b1fa2, 0 0 10px #4a148c;
  }
`;
const FournisseurPage = () => {
  // State variables
  const [fournisseurName, setFournisseurName] = useState("");
  const [fournisseurNumero, setFournisseurNumero] = useState("");
  const [fournisseurImage, setFournisseurImage] = useState("");
  const [fournisseurListDeProduit, setFournisseurListDeProduit] = useState("");
  const [selectedFactureId, setSelectedFactureId] = useState(null);
  const [factures, setFactures] = useState([]);
  const [fournisseurs, setFournisseurs] = useState([]);
  const [selectedFournisseurId, setSelectedFournisseurId] = useState(null);
  const [openFournisseurDialog, setOpenFournisseurDialog] = useState(false);
  const [expandedFournisseur, setExpandedFournisseur] = useState(null);
  const [showFactures, setShowFactures] = useState(false);
  const restaurantId = localStorage.getItem("restaurantId");
  const [customType, setCustomType] = useState("");
  // Data handling functions
  const handleAddFournisseur = async () => {
    try {
      const listDeProduit = fournisseurListDeProduit === 'autre' 
        ? customType 
        : fournisseurListDeProduit;
      const fournisseur = new Fournisseur(
        fournisseurName,
        fournisseurNumero,
        listDeProduit,
        fournisseurImage,
        restaurantId
      );
      await fournisseur.addFournisseur();
      alert("Fournisseur added successfully!");
      resetFournisseurForm();
      fetchFournisseurs();
    } catch (error) {
      console.error("Error adding fournisseur:", error);
      alert("Error adding fournisseur");
    }
  };

  const fetchFournisseurs = async () => {
    try {
      const fournisseursList = await Fournisseur.getAllFournisseurs(restaurantId);
      setFournisseurs(fournisseursList);
    } catch (error) {
      console.error("Error fetching fournisseurs:", error);
    }
  };

  const handleDeleteFournisseur = async (id) => {
    try {
      await Fournisseur.deleteFournisseur(id);
      alert("Fournisseur deleted successfully!");
      fetchFournisseurs();
    } catch (error) {
      console.error("Error deleting fournisseur:", error);
      alert("Error deleting fournisseur");
    }
  };

  const fetchFactures = async (fournisseurId) => {
    try {
      const facturesList = await Facture.getFacturesByFournisseurId(fournisseurId);
      setFactures(facturesList);
    } catch (error) {
      console.error("Error fetching factures:", error);
    }
  };

  const resetFournisseurForm = () => {
    setFournisseurName("");
    setFournisseurNumero("");
    setFournisseurImage("");
    setFournisseurListDeProduit("");
    setCustomType("");
    setOpenFournisseurDialog(false);
  };

  useEffect(() => {
    fetchFournisseurs();
  }, []);

  // Helper function for avatar color
  const stringToColor = (string) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    return `hsl(${hash % 360}, 70%, 40%)`;
  };

  return (
    <Box sx={{ 
      p: 3, 
      backgroundColor: '#f5f7fb', 
      minHeight: '100vh',
      position: 'relative'
    }}>
      {/* Floating Action Button */}
      <Button
      variant="text"
      sx={{
        position: "fixed",
        bottom: 32,
        right: 32,
        color: "white",
        background: "linear-gradient(135deg, #7b1fa2 0%, #4a148c 100%)",
        padding: "10px 20px",
        borderRadius: "8px",
        fontWeight: "bold",
        textTransform: "uppercase",
        border: "2px solid transparent",
        animation: `${glowing} 1.5s infinite alternate`,
        transition: "0.3s ease-in-out",
        "&:hover": {
          background: "linear-gradient(135deg, #6a1b9a 0%, #38006b 100%)",
        },
      }}
      onClick={() => setOpenFournisseurDialog(true)}
    >
      AJOUTER UN FOURNISSEUR
    </Button>

      {/* Animated Header */}
      <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Box sx={{ 
          mb: 4, 
          p: 4, 
          borderRadius: 4,
          background: 'linear-gradient(135deg, #7b1fa2 0%, #4a148c 100%)',
          boxShadow: 3,
          color: 'white',
          textAlign: 'center'
        }}>
          <Article sx={{ fontSize: 48, mb: 2 }} />
          <Typography variant="h3" sx={{ fontWeight: 800, letterSpacing: 1.5 }}>
            Gestion Fournisseurs
          </Typography>
          <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
            Gérez vos fournisseurs et factures efficacement
          </Typography>
        </Box>
      </motion.div>

      {/* Suppliers Grid */}
      <Grid container spacing={3} sx={{ mb: 8 }}>
        {fournisseurs.map((fournisseur) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={fournisseur.id}>
            <MotionCard
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              whileHover={{ scale: 1.02 }}
              sx={{
                borderRadius: 3,
                boxShadow: 3,
                background: expandedFournisseur === fournisseur.id ? 
                  'linear-gradient(45deg, #f8f9fa 0%, #ffffff 100%)' : 'white',
              }}
            >
              <CardContent>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 2,
                    cursor: 'pointer',
                  }} 
                  onClick={() => setExpandedFournisseur(
                    expandedFournisseur === fournisseur.id ? null : fournisseur.id
                  )}
                >
                  <Avatar 
                    sx={{ 
                      width: 56, 
                      height: 56, 
                      mr: 2,
                      bgcolor: stringToColor(fournisseur.name),
                      fontSize: 24,
                      fontWeight: 700
                    }}
                  >
                    {fournisseur.name.charAt(0)}
                  </Avatar>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                      {fournisseur.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {fournisseur.numero}
                    </Typography>
                    <Chip 
                        label={fournisseur.listDeProduit}
                        color="primary"
                        variant="outlined"
                        sx={{ borderRadius: 1 }}
                      />
                    
                  </Box>
                  <UnfoldMore sx={{ 
                    color: 'action.active', 
                    transform: expandedFournisseur === fournisseur.id ? 'rotate(180deg)' : 'none',
                    transition: 'transform 0.3s ease'
                  }} />
                </Box>

                <Collapse in={expandedFournisseur === fournisseur.id}>
                  <Box sx={{ pl: 7, pr: 2 }}>
                    <Divider sx={{ my: 2 }} />
                   

                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button
                        variant="contained"
                        startIcon={<Visibility />}
                        onClick={() => {
                          setSelectedFournisseurId(fournisseur.id);
                          setShowFactures(true);
                          fetchFactures(fournisseur.id);
                        }}
                        fullWidth
                        sx={{
                          background: 'linear-gradient(135deg, #7b1fa2 0%, #4a148c 100%)',
                          color: 'white',
                          '&:hover': { opacity: 0.9 }
                        }}
                      >
                         Factures
                      </Button>
                      <Tooltip title="Supprimer Fournisseur">
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteFournisseur(fournisseur.id)}
                          sx={{ '&:hover': { backgroundColor: 'rgba(244, 67, 54, 0.08)' } }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Collapse>
              </CardContent>
            </MotionCard>
          </Grid>
        ))}
      </Grid>

      {/* Slide-out Facture Panel */}
      <Slide direction="left" in={showFactures} mountOnEnter unmountOnExit>
        <Box sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: { xs: '100%', md: '40%' },
          height: '100vh',
          bgcolor: 'background.paper',
          boxShadow: 10,
          p: 4,
          overflowY: 'auto',
          zIndex: 1200
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              Gestion des Factures
            </Typography>
            <IconButton 
              onClick={() => {
                setShowFactures(false);
                setSelectedFournisseurId(null);
              }}
              sx={{ bgcolor: 'grey.100', '&:hover': { bgcolor: 'grey.200' } }}
            >
              <ArrowBack />
            </IconButton>
          </Box>
          
          <FactureComponent 
            fournisseurId={selectedFournisseurId} 
            onClose={() => setShowFactures(false)}
          />
        </Box>
      </Slide>

      {/* Excel Import Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        <Card sx={{ 
          borderRadius: 3,
          boxShadow: 3,
          border: '2px dashed #7b1fa2',
          background: 'rgba(123, 31, 162, 0.05)',
          '&:hover': {
            borderStyle: 'solid',
            background: 'rgba(123, 31, 162, 0.08)'
          }
        }}>
          <CardContent sx={{ textAlign: 'center', p: 4 }}>
            <ImportExport sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 700 }}>
              Export Excel
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
            Exportez un fichier Excel en cliquant ci-dessous.
            </Typography>
            <ExcelTableFournisseur />
          </CardContent>
        </Card>
      </motion.div>

      {/* Add Fournisseur Dialog */}
      <Dialog 
        open={openFournisseurDialog} 
        onClose={() => setOpenFournisseurDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          bgcolor: 'primary.main',
          color: 'white'
        }}>
          Nouveau Fournisseur
          <IconButton onClick={resetFournisseurForm} edge="end" color="inherit">
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ pt: 3 }}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Nom du Fournisseur"
          variant="outlined"
          value={fournisseurName}
          onChange={(e) => setFournisseurName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Numéro de Téléphone"
          variant="outlined"
          value={fournisseurNumero}
          onChange={(e) => setFournisseurNumero(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Type d'Achat</InputLabel>
          <Select
            value={fournisseurListDeProduit}
            onChange={(e) => {
              setFournisseurListDeProduit(e.target.value);
              if (e.target.value !== 'autre') setCustomType('');
            }}
            label="Type d'Achat"
          >
            <MenuItem value="matiere premiere">Matière Première</MenuItem>
            <MenuItem value="loyer">Loyer</MenuItem>
            <MenuItem value="electricité">Électricité</MenuItem>
            <MenuItem value="sonaide">Sonaide</MenuItem>
            <MenuItem value="autre">Autre</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      
      {/* New Conditional Input Field */}
      {fournisseurListDeProduit === 'autre' && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Précisez le type d'achat"
            value={customType}
            onChange={(e) => setCustomType(e.target.value)}
          />
        </Grid>
      )}
    </Grid>
  </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <Button 
            onClick={resetFournisseurForm}
            variant="outlined"
            color="inherit"
          >
            Annuler
          </Button>
          <Button 
            onClick={handleAddFournisseur}
            variant="contained"
            color="primary"
            startIcon={<Add />}
          >
            Créer Fournisseur
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FournisseurPage;
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";

// Initialize Firestore
const db = getFirestore();

class Facture {
  constructor(

    fournisseurId,
    restaurantId,
    numero,
    achatHTVA,
    tvaDeductible,
    achatTTC,
    montantPaye,
    statut,
    resteAPayer,
    modeDePaiement,
    paymentnum,
    date = new Date()
  ) {
  
    this.fournisseurId = fournisseurId;
    this.restaurantId = restaurantId;
    this.numero = numero;
    this.achatHTVA = achatHTVA;
    this.tvaDeductible = tvaDeductible;
    this.achatTTC = achatTTC;
    this.montantPaye = montantPaye;
    this.statut = statut;
    this.resteAPayer = resteAPayer;
    this.modeDePaiement = modeDePaiement;
    this.paymentnum = paymentnum;
    this.date = date;
    this.id = ""; // Assigned after saving
  }

  // Convert object to Firestore format
  toFirestore() {
    return {

      fournisseurId: this.fournisseurId,
      restaurantId: this.restaurantId,
      numero: this.numero,
      achatHTVA: this.achatHTVA,
      tvaDeductible: this.tvaDeductible,
      achatTTC: this.achatTTC,
      montantPaye: this.montantPaye,
      statut: this.statut,
      resteAPayer: this.resteAPayer,
      modeDePaiement: this.modeDePaiement,
      paymentnum: this.paymentnum,
      date: this.date,
    };
  }

  // Save facture to Firestore
  async saveFacture() {
    const factureRef = doc(collection(db, "factures"));
    this.id = factureRef.id;
    await setDoc(factureRef, this.toFirestore());
  }

  // Get facture by ID
  static async getFactureById(factureId) {
    const factureRef = doc(db, "factures", factureId);
    const factureSnapshot = await getDoc(factureRef);
    if (!factureSnapshot.exists()) throw new Error("Facture not found");
    return { id: factureSnapshot.id, ...factureSnapshot.data() };
  }

  // Update facture by ID (with validation)
  static async updateFacture(factureId, fournisseurId, updatedData) {
    const factureRef = doc(db, "factures", factureId);
    const factureSnapshot = await getDoc(factureRef);

    if (!factureSnapshot.exists()) throw new Error("Facture not found");

    const factureData = factureSnapshot.data();
    if (factureData.fournisseurId !== fournisseurId) {
      throw new Error("Fournisseur ID does not match");
    }

    await updateDoc(factureRef, updatedData);
    console.log("Facture updated successfully");
  }

  // Delete facture by ID
  static async deleteFacture(factureId) {
    await deleteDoc(doc(db, "factures", factureId));
  }

  // Get all factures
  static async getAllFactures() {
    const factures = [];
    const querySnapshot = await getDocs(collection(db, "factures"));
    querySnapshot.forEach((doc) => {
      factures.push({ id: doc.id, ...doc.data() });
    });
    return factures;
  }

  // Get factures by fournisseur & restaurant
  static async getFacturesByFournisseurAndRestaurant(fournisseurId, restaurantId) {
    const q = query(
      collection(db, "factures"),
      where("fournisseurId", "==", fournisseurId),
      where("restaurantId", "==", restaurantId)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  }

  // Get factures by fournisseur ID
  static async getFacturesByFournisseurId(fournisseurId) {
    const q = query(collection(db, "factures"), where("fournisseurId", "==", fournisseurId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  }

  // Get factures by restaurant ID (NEW METHOD)
  static async getFacturesByRestaurantId(restaurantId) {
    const q = query(collection(db, "factures"), where("restaurantId", "==", restaurantId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  }
}

export default Facture;

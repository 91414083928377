import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Snackbar,
  Alert,
  Tabs,
  Tab,
  Chip,
  IconButton,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  AttachMoney as AttachMoneyIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import Partenaire from "../Services/Partenaire";
import ExcelTablePartenaire from "../Components/TableExcel/ExcelPartenaire";
import MontantDateStatueList from "../Components/MontantStauteComponent";

const PartenairesPage = () => {
  const [partenaires, setPartenaires] = useState([]);
  const [selectedPartenaire, setSelectedPartenaire] = useState(null);
  const [encaissements, setEncaissements] = useState([]);
  const [newPartenaire, setNewPartenaire] = useState({ name: "", image: "", commission: "" });
  const [editingPartenaire, setEditingPartenaire] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [showMontantDateStatue, setShowMontantDateStatue] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [partenaireToDelete, setPartenaireToDelete] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const restaurantId = localStorage.getItem("restaurantId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const fetchedPartenaires = await Partenaire.getAllPartenaires(restaurantId);
        setPartenaires(fetchedPartenaires);
      } catch (error) {
        setError("Failed to fetch partners. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [restaurantId]);

  const handleAddPartenaire = async () => {
    if (!newPartenaire.name || !newPartenaire.commission) {
      setError("Name and Commission are required fields");
      return;
    }

    try {
      const partenaire = new Partenaire(
        newPartenaire.name,
        newPartenaire.image,
        newPartenaire.commission,
        restaurantId
      );
      await partenaire.addPartenaire();
      setPartenaires([...partenaires, { ...newPartenaire, id: partenaire.id }]);
      setNewPartenaire({ name: "", image: "", commission: "" });
      setOpenDialog(false);
    } catch (error) {
      setError("Failed to add partner. Please try again.");
    }
  };

  const handleSaveEditPartenaire = async () => {
    try {
      const updatedPartenaire = new Partenaire(
        newPartenaire.name,
        newPartenaire.image,
        newPartenaire.commission,
        restaurantId
      );
      await updatedPartenaire.updatePartenaire(editingPartenaire.id);
      const updatedList = partenaires.map((partenaire) =>
        partenaire.id === editingPartenaire.id ? { ...partenaire, ...newPartenaire } : partenaire
      );
      setPartenaires(updatedList);
      setEditingPartenaire(null);
      setNewPartenaire({ name: "", image: "", commission: "" });
    } catch (error) {
      setError("Failed to update partner. Please try again.");
    }
  };

  const handleDeleteConfirmation = (id) => {
    setPartenaireToDelete(id);
    setDeleteConfirmOpen(true);
  };

  const handleDeletePartenaire = async () => {
    try {
      await Partenaire.deletePartenaire(partenaireToDelete, restaurantId);
      setPartenaires(partenaires.filter((partenaire) => partenaire.id !== partenaireToDelete));
      setDeleteConfirmOpen(false);
    } catch (error) {
      setError("Failed to delete partner. Please try again.");
    }
  };

  const handleViewEncaissements = async (name) => {
    try {
      const fetchedEncaissements = await Partenaire.getEncaissementsForPartenaire(name, restaurantId);
      setEncaissements(fetchedEncaissements);
      setSelectedPartenaire(name);
      setTabValue(0);
    } catch (error) {
      setError("Failed to fetch payments. Please try again.");
    }
  };

  const handleCloseError = () => setError(null);

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" sx={{ color: 'primary.main', fontWeight: 700 }}>
          Partner Management
        </Typography>
        <Button
          variant="contained"
          startIcon={<CheckCircleIcon />}
          onClick={() => setOpenDialog(true)}
          sx={{ borderRadius: 2 }}
        >
          Add New Partner
        </Button>
      </Box>

      {/* Error Snackbar */}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this partner?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDeletePartenaire} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add/Edit Partner Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
          {editingPartenaire ? "Edit Partner" : "Add New Partner"}
        </DialogTitle>
        <DialogContent sx={{ py: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Avatar
                src={newPartenaire.image}
                sx={{ width: 120, height: 120, mb: 2, border: '2px solid #3f51b5' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Partner Name"
                variant="outlined"
                required
                value={newPartenaire.name}
                onChange={(e) => setNewPartenaire({ ...newPartenaire, name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Image URL"
                variant="outlined"
                value={newPartenaire.image}
                onChange={(e) => setNewPartenaire({ ...newPartenaire, image: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Commission (%)"
                variant="outlined"
                type="number"
                required
                value={newPartenaire.commission}
                onChange={(e) => setNewPartenaire({ ...newPartenaire, commission: e.target.value })}
                InputProps={{ endAdornment: '%' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={() => setOpenDialog(false)} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={editingPartenaire ? handleSaveEditPartenaire : handleAddPartenaire}
            variant="contained"
            color="primary"
          >
            {editingPartenaire ? "Save Changes" : "Create Partner"}
          </Button>
        </DialogActions>
      </Dialog>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Grid container spacing={3}>
         {partenaires
          .filter((partenaire) => partenaire.restaurantId === restaurantId) // Filter based on restaurantId
          .map((partenaire) => (
            <Grid item xs={12} sm={6} md={4} key={partenaire.id}>
              <Card sx={{
                borderRadius: 3,
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': { transform: 'translateY(-5px)', boxShadow: 6 }
              }}>
                <CardContent>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar
                      src={partenaire.image}
                      sx={{ width: 80, height: 80, mb: 2, bgcolor: 'primary.main' }}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      {partenaire.name}
                    </Typography>
                    <Chip
                      label={`${partenaire.commission}% Commission`}
                      color="secondary"
                      size="small"
                      sx={{ mt: 1, mb: 2 }}
                    />
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Button
                      variant="outlined"
                      startIcon={<VisibilityIcon />}
                      onClick={() => handleViewEncaissements(partenaire.name)}
                      fullWidth
                    >
                      View Payments
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={() => {
                        setEditingPartenaire(partenaire);
                        setNewPartenaire(partenaire);
                        setOpenDialog(true);
                      }}
                      fullWidth
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDeleteConfirmation(partenaire.id)}
                      fullWidth
                    >
                      Delete
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<AttachMoneyIcon />}
                      onClick={() => {
                        setSelectedPartenaire(partenaire.id);
                        setShowMontantDateStatue(true);
                        setTabValue(1);
                      }}
                      fullWidth
                      sx={{ mt: 1 }}
                    >
                      Financial Details
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {selectedPartenaire && (
        <Box sx={{ mt: 4, border: '1px solid #e0e0e0', borderRadius: 2, p: 3 }}>
          <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} sx={{ mb: 3 }}>
            <Tab label="Payment History" />
            <Tab label="Financial Details" />
          </Tabs>

          {tabValue === 0 && (
            <ExcelTablePartenaire
              partenairesData={partenaires}
              encaissement={encaissements}
              selectedPartenaire={selectedPartenaire}
            />
          )}

          {tabValue === 1 && (
            <MontantDateStatueList partenaireId={selectedPartenaire} />
          )}
        </Box>
      )}
    </Container>
  );
};

export default PartenairesPage;
import React, { useState, useEffect } from 'react';
import { 
  Box, FormControl, InputLabel, Select, MenuItem, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Chip, Typography, Grid, Card, IconButton,TextField
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { query, collection, where, getDocs, getFirestore } from 'firebase/firestore';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';
import { FileDownload } from '@mui/icons-material';

const ExcelTableFournisseur = () => {
  const [factures, setFactures] = useState([]);
  const [fournisseurs, setFournisseurs] = useState([]);
  const [searchType, setSearchType] = useState('');
  const [searchStatue, setSearchStatue] = useState('');
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const restaurantId = localStorage.getItem("restaurantId");
  const db = getFirestore();
  
  const exportToExcel = () => {
    const tableData = filteredFactures.map(facture => ({
      Date: facture.date.toLocaleDateString('fr-FR'),
      Fournisseur: fournisseurs.find(f => f.id === facture.fournisseurId)?.name || 'Inconnu',
      "Achat HTVA": facture.achatHTVA,
      "TVA Déductible": facture.tvaDeductible,
      "Achat TTC": facture.achatTTC,
      "Montant Payé": facture.montantPaye,
      Statut: facture.statut,
      "Reste à Payer": facture.resteAPayer,
      "Mode de paiement": facture.modeDePaiement
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Factures");
  
    XLSX.writeFile(workbook, `Factures_${currentMonth + 1}_${currentYear}.xlsx`);
  };

  // Get start and end dates for current month
  const getMonthRange = () => {
    const startDate = new Date(currentYear, currentMonth, 1);
    const endDate = new Date(currentYear, currentMonth + 1, 0);
    endDate.setHours(23, 59, 59, 999); // Include entire last day
    return { startDate, endDate };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch fournisseurs
        const fournisseursQuery = query(
          collection(db, "fournisseurs"),
          where("restaurantId", "==", restaurantId)
        );
        const fournisseursSnapshot = await getDocs(fournisseursQuery);
        setFournisseurs(fournisseursSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

        // Fetch factures
        const facturesQuery = query(
          collection(db, "factures"),
          where("restaurantId", "==", restaurantId)
        );
        const facturesSnapshot = await getDocs(facturesQuery);
        
        const facturesData = facturesSnapshot.docs.map(doc => {
          const data = doc.data();
          // Convert Firestore Timestamp to Date
          const date = data.date?.toDate ? data.date.toDate() : new Date(data.date);
          return { id: doc.id, ...data, date };
        });

        setFactures(facturesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (restaurantId) fetchData();
  }, [restaurantId, db]);

  const { startDate, endDate } = getMonthRange();

  const filteredFactures = factures.filter(facture => {
    // Filter by fournisseur
    if (searchType && fournisseurs.find(f => f.id === facture.fournisseurId)?.name !== searchType) {
      return false;
    }
  
    // Filter by statut (Fixed the incorrect find usage)
    if (searchStatue && facture.statut !== searchStatue) {
      return false;
    }
  
    // Filter by date range
    const factureDate = new Date(facture.date); // Ensure it's a Date object
    return factureDate >= startDate && factureDate <= endDate;
  });
  

  const handleMonthChange = (offset) => {
    const newDate = new Date(currentYear, currentMonth + offset);
    setCurrentMonth(newDate.getMonth());
    setCurrentYear(newDate.getFullYear());
  };

  const MonthYearPicker = () => (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <IconButton onClick={() => handleMonthChange(-1)}>
        <ChevronLeft />
      </IconButton>
      
      <FormControl variant="outlined" sx={{ minWidth: 120 }}>
        <Select
          value={currentMonth}
          onChange={(e) => setCurrentMonth(Number(e.target.value))}
        >
          {Array.from({ length: 12 }, (_, i) => (
            <MenuItem key={i} value={i}>
              {new Date(currentYear, i).toLocaleString('default', { month: 'long' })}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        type="number"
        value={currentYear}
        onChange={(e) => setCurrentYear(Math.max(1970, Math.min(2100, Number(e.target.value))))}
        sx={{ width: 100 }}
        inputProps={{ min: 1970, max: 2100 }}
      />

      <IconButton onClick={() => handleMonthChange(1)}>
        <ChevronRight />
      </IconButton>
    </Box>
  );

  const getStatutStyle = (statut) => {
    const styles = {
      borderRadius: '4px',
      padding: '4px 8px',
      fontWeight: 600,
      fontSize: '0.75rem'
    };

    switch (statut) {
      case 'Payée': return { ...styles, backgroundColor: '#e8f5e9', color: '#2e7d32' };
      case 'Non Payée': return { ...styles, backgroundColor: '#ffebee', color: '#c62828' };
      case 'En Attente': return { ...styles, backgroundColor: '#fff3e0', color: '#ef6c00' };
      default: return styles;
    }
  };

  return (
    <div>
           <Button 
      variant="contained" 
      color="primary" 
      startIcon={<FileDownload />}
      onClick={exportToExcel}
      sx={{ mb: 2 }}
    >
      Exporter en Excel
    </Button>
    <Card sx={{ p: 3, borderRadius: 4, boxShadow: 3 }}>
   
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Filtrer par fournisseur</InputLabel>
            <Select 
              value={searchType} 
              onChange={(e) => setSearchType(e.target.value)}
              label="Filtrer par fournisseur"
            >
              <MenuItem value="">Tous les fournisseurs</MenuItem>
              {[...new Set(fournisseurs.map(e => e.name))].map(name => (
                <MenuItem key={name} value={name}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Filtrer Statue</InputLabel>
            <Select 
              value={searchStatue} 
              onChange={(e) => setSearchStatue(e.target.value)}
              label="Filtrer statue"
            >
              <MenuItem value="">Statue</MenuItem>
              {[...new Set(factures.map(e => e.statut))].map(statut => (
                <MenuItem key={statut} value={statut}>{statut}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <MonthYearPicker />
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ borderRadius: 3, overflow: 'hidden' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ bgcolor: 'primary.main', color: 'white', fontWeight: 600 }}>Date</TableCell>
              {['Fournisseur', 'Achat HTVA', 'TVA Déductible', 'Achat TTC', 
                'Montant Payé', 'Statut', 'Reste à Payer', 'Mode de paiement'].map(header => (
                <TableCell 
                  key={header}
                  sx={{ bgcolor: 'primary.light', color: 'white', fontWeight: 600 }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          
          <TableBody>
            {filteredFactures.map((facture, index) => (
              <TableRow key={index} hover>
                <TableCell>{facture.date.toLocaleDateString('fr-FR')}</TableCell>
                <TableCell>{fournisseurs.find(f => f.id === facture.fournisseurId)?.name || 'Inconnu'}</TableCell>
                <TableCell>{facture.achatHTVA}</TableCell>
                <TableCell>{facture.tvaDeductible}</TableCell>
                <TableCell>{facture.achatTTC}</TableCell>
                <TableCell>{facture.montantPaye}</TableCell>
                <TableCell>
                  <Chip label={facture.statut} sx={getStatutStyle(facture.statut)} />
                </TableCell>
                <TableCell>{facture.resteAPayer}</TableCell>
                <TableCell>{facture.modeDePaiement}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {filteredFactures.length === 0 && (
          <Box sx={{ p: 4, textAlign: 'center' }}>
            <Typography variant="body1" color="text.secondary">
              Aucune donnée trouvée pour {new Date(currentYear, currentMonth).toLocaleString('fr-FR', { 
                month: 'long', 
                year: 'numeric' 
              })}
            </Typography>
          </Box>
        )}
      </TableContainer>
    </Card>
    </div>
  );
};

export default ExcelTableFournisseur;